import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";

const useCreatePortal = (el) => {
  const [portal, setPortal] = useState({
    render: () => null,
    remove: () => null
  });

  const generatePortal = useCallback((el) => {
    const Portal = ({ children }) => el && createPortal(children, el);
    return { render: Portal, remove: () => {} };
  }, []);

  useEffect(() => {
    const newPortal = generatePortal(el);
    setPortal(newPortal);
    return () => {
      if (newPortal && el) newPortal.remove(el);
    };
  }, [el, createPortal]);

  return portal.render;
};

export default useCreatePortal;

import React from "react";
import clsx from "clsx";
import { BubbleMenu } from "@tiptap/react";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import TitleIcon from "@mui/icons-material/Title";
import LinkIcon from "@mui/icons-material/Link";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import FormatClearIcon from "@mui/icons-material/FormatClear";
import TooltipWithIntl from "../tooltip/TooltipWithIntl";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.textEditor.border}`,
    marginBottom: theme.spacing(2)
  },
  toolbarActions: {
    "& :first-child": {
      display: "flex",
      gap: "8px"
    },
    "& .MuiButtonBase-root": {
      color: theme.palette.text.secondary,
      width: "28px",
      height: "28px"
    }
  },
  bubbleMenu: {
    display: "flex",
    padding: "4px",
    background: theme.palette.textEditor.bubbleMenu.inactiveBackgorund,
    border: `1px solid ${theme.palette.textEditor.bubbleMenu.border}`,
    borderRadius: "11.2px",
    boxShadow:
      "0px 12px 33px 0px rgba(0, 0, 0, 0.06), 0px 3.618px 9.949px 0px rgba(0, 0, 0, 0.04)"
  },
  toolbarButton: {
    minWidth: "unset",
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    borderColor: "transparent",
    backgroundColor: "unset",
    width: "28px",
    height: "28px",
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.textEditor.bubbleMenu.inactiveHover,
      borderRadius: "8px"
    },
    "&.is-active": {
      backgroundColor: theme.palette.textEditor.bubbleMenu.activeBackgorund,
      "&:hover": {
        backgroundColor: theme.palette.textEditor.bubbleMenu.activeHover
      }
    }
  }
}));

const ToolbarButton = ({ onClick, isActive, name, children }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <TooltipWithIntl
      intlStringId={`textEditor.tooltips.${name}`}
      defaultMessage={`textEditor.tooltips.${name}`}
      placement={"top"}>
      <Button
        aria-label={intl.formatMessage({
          id: `textEditor.tooltips.${name}`,
          defaultMessage: "Toolbar button"
        })}
        className={clsx(classes.toolbarButton, isActive ? "is-active" : "")}
        onClick={onClick}
        variant="text"
        size="small">
        {children}
      </Button>
    </TooltipWithIntl>
  );
};

const toolbarItems = [
  {
    name: "heading",
    icon: TitleIcon,
    action: (editor) =>
      editor.chain().focus().toggleHeading({ level: 4 }).run(),
    isActive: (editor) => editor.isActive("heading", { level: 4 })
  },
  {
    name: "paragraph",
    icon: TextFieldsIcon,
    action: (editor) => editor.chain().focus().setParagraph().run(),
    isActive: (editor) => editor.isActive("paragraph")
  },
  {
    name: "bold",
    icon: FormatBoldIcon,
    action: (editor) => editor.chain().focus().toggleBold().run(),
    isActive: (editor) => editor.isActive("bold")
  },
  {
    name: "underline",
    icon: FormatUnderlinedIcon,
    action: (editor) => editor.chain().focus().toggleUnderline().run(),
    isActive: (editor) => editor.isActive("underline")
  },
  {
    name: "italic",
    icon: FormatItalicIcon,
    action: (editor) => editor.chain().focus().toggleItalic().run(),
    isActive: (editor) => editor.isActive("italic")
  },
  {
    name: "bulletList",
    icon: FormatListBulletedIcon,
    action: (editor) => editor.chain().focus().toggleBulletList().run(),
    isActive: (editor) => editor.isActive("bulletList")
  },
  {
    name: "orderedList",
    icon: FormatListNumberedIcon,
    action: (editor) => editor.chain().focus().toggleOrderedList().run(),
    isActive: (editor) => editor.isActive("orderedList")
  },
  {
    name: "link",
    icon: LinkIcon,
    action: (editor) => {
      const previousUrl = editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);
      if (url === null) {
        return;
      }
      if (url === "") {
        editor.chain().focus().extendMarkRange("link").unsetLink().run();
        return;
      }
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
    isActive: (editor) => editor.isActive("link")
  },
  {
    name: "clearFormatting",
    icon: FormatClearIcon,
    action: (editor) =>
      editor.chain().focus().clearNodes().unsetAllMarks().run(),
    isActive: (editor) => {}
  }
];

const NewTextEditorToolbar = ({
  editor,
  toolbar,
  toolbarOptions = [
    "bold",
    "underline",
    "italic",
    "bulletList",
    "orderedList",
    "link",
    "clearFormatting"
  ],
  actions
}) => {
  const classes = useStyles();

  if (toolbar === "fixed") {
    return (
      <Box className={clsx(classes.toolbar, "editor-toolbar")}>
        <Box>
          {toolbarItems
            .filter((item) => toolbarOptions.includes(item.name))
            .map((item, index) => (
              <ToolbarButton
                key={index}
                name={item.name}
                onClick={() => item.action(editor)}
                isActive={item.isActive(editor)}>
                <item.icon sx={{ fontSize: "20px" }} />
              </ToolbarButton>
            ))}
        </Box>
        <Box className={classes.toolbarActions}>{actions}</Box>
      </Box>
    );
  }

  return (
    <BubbleMenu
      className={classes.bubbleMenu}
      tippyOptions={{ duration: 0 }}
      editor={editor}>
      {toolbarItems
        .filter((item) => toolbarOptions.includes(item.name))
        .map((item, index) => (
          <ToolbarButton
            key={index}
            name={item.name}
            onClick={() => item.action(editor)}
            isActive={item.isActive(editor)}>
            <item.icon sx={{ fontSize: "20px" }} />
          </ToolbarButton>
        ))}
    </BubbleMenu>
  );
};

export default NewTextEditorToolbar;

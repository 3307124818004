export const getInitials = (fullName, minimized) => {
  try {
    const allNames = fullName.trim().split(" ");
    let initials = allNames.reduce((acc, curr, index) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, "");
    if (minimized) {
      initials = initials.slice(0, 1);
    }
    return initials;
  } catch (error) {
    return "A";
  }
};

// Helper functions
const hslToRgb = (h, s, l) => {
  let r, g, b;

  if (s === 0) {
    r = g = b = l;
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [r * 255, g * 255, b * 255];
};

const getLuminance = (r, g, b) => {
  const [rs, gs, bs] = [r, g, b].map((c) => {
    c = c / 255;
    return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * rs + 0.7152 * gs + 0.0722 * bs;
};

const getContrastRatio = (l1, l2) => {
  const lighter = Math.max(l1, l2);
  const darker = Math.min(l1, l2);
  return (lighter + 0.05) / (darker + 0.05);
};

// Generate a background color from a string
export const generateBackgroundColor = (str, overrideColor = null) => {
  if (!str) return "#FFFFFF";

  if (overrideColor) {
    return overrideColor.startsWith("#") ? overrideColor : `#${overrideColor}`;
  }

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Generate more vibrant colors by using HSL
  const hue = Math.abs(hash % 360);
  const saturation = 65 + Math.abs((hash >> 8) % 20); // 65-85%
  const lightness = 45 + Math.abs((hash >> 16) % 20); // 45-65%

  // Convert HSL to hex
  const h = hue / 360;
  const s = saturation / 100;
  const l = lightness / 100;

  const rgb = hslToRgb(h, s, l);
  const hex = rgb
    .map((x) => {
      const hex = Math.round(x).toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    })
    .join("");

  return `#${hex}`;
};

// Generate foreground color ensuring WCAG AA compliance (4.5:1 for normal text)
export const generateAccessibleTextColor = (backgroundColor) => {
  if (!backgroundColor.startsWith("#")) {
    backgroundColor = `#${backgroundColor}`;
  }

  const r = parseInt(backgroundColor.substr(1, 2), 16);
  const g = parseInt(backgroundColor.substr(3, 2), 16);
  const b = parseInt(backgroundColor.substr(5, 2), 16);

  const bgLuminance = getLuminance(r, g, b);

  // Check contrast with white and black
  const whiteContrast = getContrastRatio(1, bgLuminance);
  const blackContrast = getContrastRatio(0, bgLuminance);

  // WCAG AA requires 4.5:1 for normal text
  return whiteContrast >= 4.5
    ? "#FFFFFF"
    : blackContrast >= 4.5
      ? "#000000"
      : whiteContrast > blackContrast
        ? "#FFFFFF"
        : "#000000";
};

import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Box } from "@mui/material";
import { PangeaInputBase } from "./SharedComponents";
import { useOnClickOutside } from "../hooks";

const useStyles = makeStyles((theme) => ({
  fontSize: {
    fontSize: "16px",
    lineHeight: "24px"
  },
  highSpan: {
    height: "1.1876em",
    margin: "0",
    padding: "6px 0 7px",
    fontSize: "16px",
    lineHeight: "24px"
  },
  btnContainer: {
    width: "100%",
    minHeight: "24px",
    position: "relative"
  },

  input: {
    width: "100%",
    minHeight: "20px",
    padding: "0px",
    textAlign: "initial",
    "&::-webkit-input-placeholder": {
      /* Chrome/Opera/Safari */ color: "white"
    },
    "&::-moz-placeholder": {
      /* Firefox 19+ */ color: "white"
    },
    "&::-ms-input-placeholder": {
      /* IE 10+ */ color: "white"
    },
    "& ::-moz-placeholder": {
      /* Firefox 18- */ color: "white"
    }
  },

  fullLine: {
    width: "100%",
    minHeight: "100%",
    paddingInline: theme.spacing(0.5),
    paddingBlock: theme.spacing(1.5)
  },
  editBtn: {
    paddingLeft: "4px",
    paddingRight: "4px",
    position: "absolute",

    paddingTop: "4px",
    paddingBottom: "4px",
    color: theme.palette.grey["400"],
    "&:hover": {
      color: theme.palette.secondary.main
    }
  }
}));

export default function EditingButton({
  text,
  onChange,
  onLeave,
  editingMode,
  onClick,
  onFocusOut,
  onKeyPress,
  multiline,
  rows,
  placeholder,
  endAdornment = null,
  renderEndAdornmentInReadonly = false,
  isNew = false,
  editIcon = null,
  colorClass = false,
  shouldFocus = true
}) {
  const classes = useStyles();
  const tempRef = useRef();
  const containerRef = useRef();
  const [focused, setFocused] = useState(isNew);

  const prevEditModeRef = useRef();
  useEffect(() => {
    prevEditModeRef.current = editingMode;
  });
  const prevEditMode = prevEditModeRef.current;

  useEffect(() => {
    if (!editingMode) {
      setFocused(false);
    }
  }, [editingMode, setFocused]);

  useEffect(() => {
    if (
      editingMode &&
      tempRef.current &&
      (shouldFocus || prevEditMode !== editingMode)
    ) {
      if (shouldFocus) {
        tempRef.current.focus();
      }
      setFocused(true);

      if (tempRef.current.setSelectionRange && tempRef.current.value) {
        tempRef.current.setSelectionRange(
          tempRef.current.value.length,
          tempRef.current.value.length
        );
      }
    }
  }, [tempRef, editingMode, setFocused, prevEditMode, shouldFocus]);

  useEffect(() => {
    let ref = tempRef;
    //force on blur on remove
    return () => {
      onLeave && ref && ref.current && onLeave(ref.current.value);
    };
  }, [onLeave]);

  const renderContent = () => {
    if (editingMode) {
      return (
        <PangeaInputBase
          data-testid="new-question-input"
          dir="auto"
          className={clsx(classes.fullLine, classes.fontSize, classes.input)}
          autoFocus={shouldFocus}
          inputRef={tempRef}
          multiline={multiline}
          minRows={rows}
          defaultValue={text}
          endAdornment={endAdornment}
          placeholder={placeholder}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      );
    } else {
      return (
        <PangeaInputBase
          dir="auto"
          className={clsx(classes.input, classes.fullLine, classes.fontSize)}
          placeholder={placeholder}
          multiline={multiline}
          defaultValue={text}
          minRows={rows}
          disabled={true}
          endAdornment={renderEndAdornmentInReadonly && endAdornment}
        />
      );
    }
  };

  const renderButton = () => {
    if (editingMode) return null;
    if (editIcon) return editIcon;
    else {
      return (
        <IconButton className={classes.editBtn} onClick={onClick} size="large">
          <EditIcon />
        </IconButton>
      );
    }
  };

  useOnClickOutside(containerRef, () => {
    if (focused) {
      setFocused(false);
      const val = tempRef.current && tempRef.current.value;

      onFocusOut && onFocusOut(val);
    }
  });

  return (
    <Box ref={containerRef} className={classes.btnContainer}>
      {renderContent()}
      {renderButton()}
    </Box>
  );
}

import { useEffect, useCallback, useState, useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import { userAPI } from "../api";
import { add } from "date-fns";
import { useSelector } from "react-redux";
import { ACTIVITY_EVENT, IDLE_PERIOD } from "../consts";

function useLogUserSteps(
  activityEvent,
  additionalPayload = {},
  isActive = false
) {
  // if the submission is not active, do not log.
  if (!isActive) {
    return;
  }
  const userId = useSelector((state) => state.firebase.auth.uid);
  // make it variable
  const lastLog = useRef("end");

  const logActivity = useCallback(
    (type) => {
      const now = new Date();
      userAPI.log({
        action_name: activityEvent,
        user_id: userId,
        created_at: now,
        ttl: add(now, { months: 1 }),
        payload: {
          type,
          ...additionalPayload
        }
      });
    },
    [activityEvent, userId, additionalPayload]
  );

  const logIdle = () => {
    if (lastLog.current !== "end") {
      lastLog.current = "end";
      const now = new Date();
      userAPI.log({
        action_name: activityEvent,
        user_id: userId,
        created_at: now,
        ttl: add(now, { months: 1 }),
        payload: {
          type: ACTIVITY_EVENT.END,
          idle_period: IDLE_PERIOD,
          ...additionalPayload
        }
      });
    }
  };

  const logStart = () => {
    if (lastLog.current !== "start") {
      lastLog.current = "start";
      logActivity(ACTIVITY_EVENT.START);
    }
  };

  const logEnd = () => {
    if (lastLog.current !== "end") {
      lastLog.current = "end";
      logActivity(ACTIVITY_EVENT.END);
    }
  };
  // Reset idle timer
  const { reset, isIdle } = useIdleTimer({
    onIdle: logIdle,
    onActive: logStart,
    timeout: IDLE_PERIOD,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove"
    ],
    element: document,
    startOnMount: true
  });

  useEffect(() => {
    if (!document.hidden) {
      logStart();
    }
    // Cleanup on component unmount
    return () => {
      if (!isIdle()) {
        logEnd();
      }
    };
  }, [activityEvent]);

  useEffect(() => {
    // When user closes/refresh the tab
    const handleBeforeUnload = () => {
      if (!isIdle()) {
        logEnd();
      }
    };
    // When user switches tabs
    const handleVisibilityChange = () => {
      if (document.hidden) {
        logEnd();
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    document.addEventListener("visibilitychange", handleVisibilityChange);
    // Cleanup
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return { reset };
}

export default useLogUserSteps;

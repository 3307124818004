import { useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  endBefore,
  onSnapshot,
  Timestamp
} from "firebase/firestore";
import {
  setDocData,
  setCollectionData,
  setError,
  setListener,
  removeListener
} from "../redux/firestoreSlice";

const serializeData = (data) => {
  // Handle null/undefined
  if (data == null) {
    return data;
  }

  // Handle Timestamp objects
  if (data instanceof Timestamp) {
    return data.seconds;
  }

  // Handle arrays
  if (Array.isArray(data)) {
    return data.map((item) => serializeData(item));
  }

  // Handle objects
  if (typeof data === "object") {
    return Object.entries(data).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: serializeData(value)
      }),
      {}
    );
  }

  // Return primitive values as is
  return data;
};

// Convert react-redux-firebase query syntax to Firebase SDK query constraints
const convertQuery = (queryConfig) => {
  if (typeof queryConfig === "string") {
    return {
      path: queryConfig,
      constraints: [],
      storeAs: queryConfig
    };
  }

  const {
    collection: collectionPath,
    doc: docId,
    subcollections = [],
    storeAs,
    where: whereConditions,
    orderBy: orderByConfig,
    limit: limitCount,
    ...rest
  } = queryConfig;

  let pathSegments = [];

  // Add base collection/doc
  pathSegments.push(collectionPath);
  if (docId) pathSegments.push(docId);

  // Add subcollections
  subcollections.forEach((sub) => {
    pathSegments.push(sub.collection);
    if (sub.doc) pathSegments.push(sub.doc);
  });

  const path = pathSegments.join("/");
  const isCollection = pathSegments.length % 2 === 1; // Odd number = collection, even = document

  const constraints = [];

  if (whereConditions) {
    const conditions = Array.isArray(whereConditions)
      ? whereConditions
      : [whereConditions];
    conditions.forEach(([field, operator, value]) => {
      constraints.push(where(field, operator, value));
    });
  }

  if (orderByConfig) {
    const orders = Array.isArray(orderByConfig)
      ? orderByConfig
      : [orderByConfig];
    orders.forEach((order) => {
      if (typeof order === "string") {
        constraints.push(orderBy(order));
      } else {
        constraints.push(orderBy(order[0], order[1]));
      }
    });
  }

  if (limitCount) {
    constraints.push(limit(limitCount));
  }

  if (rest.startAfter) constraints.push(startAfter(rest.startAfter));
  if (rest.endBefore) constraints.push(endBefore(rest.endBefore));

  return {
    path,
    constraints,
    storeAs: storeAs || path,
    isCollection
  };
};

export function useFirestoreConnect(queries) {
  const dispatch = useDispatch();
  const firestore = getFirestore();
  const unsubscribesRef = useRef({});
  const queriesRef = useRef(queries);

  const queryConfigs = useMemo(() => {
    const configs = Array.isArray(queries) ? queries : [queries];
    // Deep compare with previous queries to prevent unnecessary updates
    if (JSON.stringify(configs) === JSON.stringify(queriesRef.current)) {
      return queriesRef.current;
    }
    queriesRef.current = configs;
    return configs;
  }, [queries]);

  useEffect(() => {
    const currentPaths = new Set();

    queryConfigs.forEach((queryConfig) => {
      const { path, constraints, storeAs, isCollection } =
        convertQuery(queryConfig);
      currentPaths.add(storeAs);

      // Only set up subscription if it doesn't exist
      if (!unsubscribesRef.current[storeAs]) {
        if (!isCollection) {
          // Document subscription
          const unsubscribe = onSnapshot(
            doc(firestore, path),
            (docSnapshot) => {
              if (docSnapshot.exists()) {
                dispatch(
                  setDocData({
                    path: storeAs,
                    data: { id: docSnapshot.id, ...docSnapshot.data() }
                  })
                );
              }
            },
            (error) => {
              console.error("Firestore subscription error:", error);
              dispatch(setError({ path: storeAs, error: error.message }));
            }
          );

          unsubscribesRef.current[storeAs] = unsubscribe;
          dispatch(setListener({ path: storeAs }));
        } else {
          // Collection subscription

          const collectionRef = collection(firestore, path);
          const queryRef = query(collectionRef, ...constraints);

          const unsubscribe = onSnapshot(
            queryRef,
            (querySnapshot) => {
              const data = {};
              querySnapshot.forEach((doc) => {
                data[doc.id] = { id: doc.id, ...doc.data() };
              });

              dispatch(
                setCollectionData({
                  collection: storeAs,
                  data: serializeData(data)
                })
              );
            },
            (error) => {
              console.error("Firestore subscription error:", error);
              dispatch(setError({ path: storeAs, error: error.message }));
            }
          );

          unsubscribesRef.current[storeAs] = unsubscribe;
          dispatch(setListener({ path: storeAs }));
        }
      }
    });

    // Clean up only removed subscriptions
    return () => {
      Object.keys(unsubscribesRef.current).forEach((path) => {
        if (!currentPaths.has(path)) {
          unsubscribesRef.current[path]();
          delete unsubscribesRef.current[path];
          dispatch(removeListener({ path }));
        }
      });
    };
  }, [queryConfigs, dispatch, firestore]);
}

import { useState, useEffect, useCallback, useRef } from "react";
import { httpCallables } from "../firebase";
import { useSelector } from "react-redux";

const useRetrieveTextThumbnails = (textId) => {
  const totalPages = useSelector((state) => state.pdf.totalPages);
  const totalPagesRef = useRef(totalPages);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    totalPagesRef.current = totalPages;
  }, [totalPages]);

  const fetchFiles = useCallback(async () => {
    try {
      const { data } = await httpCallables.getThumbnailsDownloadURL({
        text_id: textId
      });
      const { success } = data;
      if (success) {
        const { urls } = JSON.parse(data.payload);
        if (urls.length && urls.length === totalPagesRef.current) {
          setFiles(urls);
          setError(null);
          setLoading(false);
          return true;
        } else {
          setError("No thumbnails");
          setFiles([]);
          return false;
        }
      }
      return false;
    } catch (err) {
      console.error("Error fetching files:", err);
      setError(err);
      setFiles([]);
      return false;
    }
  }, [textId]);

  useEffect(() => {
    if (!totalPagesRef?.current) return;

    let intervalId;

    const startFetching = async () => {
      setLoading(true);

      const success = await fetchFiles(); // Initial fetch
      if (!success) {
        // Polling
        intervalId = setInterval(async () => {
          const success = await fetchFiles();
          if (success && intervalId) {
            clearInterval(intervalId);
            intervalId = null;
          }
        }, 5000);
      }
    };
    startFetching();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [fetchFiles, totalPages]);

  return { files, loading, error };
};

export default useRetrieveTextThumbnails;

import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { PdfHighlight, PdfPosition } from "./PdfTypes";
import { useSelector, useDispatch } from "react-redux";

import { PdfPane, PdfHighlightMark, PdfUnderlineMark } from "./PdfMarks";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { getHighlightColor } from "../../../utils/colors";
import {
  ANNOTATION_TYPES,
  COMMENT_PANEL_VIEW,
  INTERACTION_TYPES
} from "../../../consts";
import PdfCFI from "../../../utils/pdf-cfi";
import {
  selectIsSelectedThreads,
  selectIsSingleThread,
  setCommentPanelState,
  setSelectedRealtimeInteractions,
  setSelectedThreadId
} from "../../../redux/realtimeInteractionsSlice";
import { scrollAnnotationIntoView } from "./utils";
import { setShouldShowLocation } from "../../../redux/pdfSlice";
import { selectDarkMode } from "../../../redux/firestoreSelectors";

const useStyles = makeStyles(() => ({
  highlightLayer: {
    position: "absolute",
    zIndex: 3,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    pointerEvents: "none",
    userSelect: "none",
    "-webkit-user-select": "none",
    mixBlendMode: "multiply"
  },
  darkModeHighlightLayer: {
    position: "absolute",
    zIndex: 3,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    pointerEvents: "none",
    userSelect: "none",
    "-webkit-user-select": "none",
    mixBlendMode: "screen"
  }
}));

const MarkTypes = {
  Highlight: "highlight",
  Underline: "underline",
  Thread: "thread"
};

// This effect sets the highlight
const filterHlByPage = (hlCollection, page) => {
  let allHighlights = [];
  if (hlCollection.length) {
    return hlCollection.flatMap((el) => {
      if (el.interaction_type === "SUGGESTION") {
        if (el.pdfPosition.pageNumber === page) {
          return [{ ...el, ...el.pdfPosition }];
        } else return [];
      } else
        return el.pdfPosition.flatMap((pagePart) => {
          if (pagePart.pageNumber === page) {
            return [{ ...el, ...pagePart }];
          } else return [];
        });
    });
  } else return allHighlights;
};

const HighlightLayer = ({
  onHighlightClick,
  highlights = [],
  underlines = [],
  pageNumber,
  rendered
}) => {
  //add scroll to selected Location
  const darkMode = useSelector((state) => selectDarkMode(state));
  const classes = useStyles();
  const ref = useRef();
  const [hlMarks, setHlMarks] = useState([]);
  const [underlineMarks, setUnderlineMarks] = useState([]);

  const [pane, setPane] = useState(null);

  const boundingRect = ref?.current?.getBoundingClientRect();
  const left = boundingRect?.left;
  const top = boundingRect?.top;
  const width = boundingRect?.width;
  const height = boundingRect?.height;
  const dispatch = useDispatch();
  const suggestions = useSelector((state) => state.interactions.suggestions);
  const isSelectedThreads = useSelector((state) =>
    selectIsSelectedThreads(state)
  );
  const isSingleThread = useSelector((state) => selectIsSingleThread(state));
  const shouldShowLocation = useSelector(
    (state) => state.pdf.shouldShowLocation
  );
  const [pageHighlights, setPageHighlights] = useState([]);
  const [pageUnderlines, setPageUnderlins] = useState([]);
  const isAnnotatorBarOpen = useSelector(
    (state) => state.highlighter.isAnnotatorBarOpen
  );

  useEffect(() => {
    setPageUnderlins(filterHlByPage(underlines, pageNumber));
  }, [underlines, pageNumber, setPageUnderlins]);

  useEffect(() => {
    setPageHighlights(filterHlByPage(highlights, pageNumber));
  }, [highlights, pageNumber, setPageHighlights]);

  useEffect(() => {
    if (ref.current && !pane && rendered) {
      const pageElement = ref.current;
      setPane(new PdfPane(pageElement.parentElement, pageElement));
    }
    if (!rendered && pane) {
      for (const annotation of [...hlMarks, ...underlineMarks]) {
        if (!annotation) continue;
        pane.removeMark(annotation.mark);
      }
    }
  }, [ref, pane, rendered]);

  useEffect(
    () => {
      const mark = (
        type,
        cfiRange,
        data = {},
        cb,
        className = "epubjs-hl",
        styles = {}
      ) => {
        const attributes = Object.assign(styles);

        const textLayerElement =
          ref.current.parentElement.getElementsByClassName("textLayer")[0];
        let range = cfiRange.toRange(textLayerElement);
        if (!range) return; //someitmes happens when unmounting, needs to not render
        let m =
          type == MarkTypes.Highlight || type == MarkTypes.Thread
            ? new PdfHighlightMark(range, className, data, attributes)
            : new PdfUnderlineMark(range, className, data, attributes);
        let h = pane.addMark(m);
        if (type === ANNOTATION_TYPES.HIGHLIGHT.toLowerCase()) {
          boundEventListenerToHighlight(h, data, className, cfiRange);
        } else if (type === MarkTypes.Thread) {
          h.element.setAttribute("ref", className);
          h.element.addEventListener("click", (e) => {
            cb(e);
          });
        }
        const highlightedText = range.toString().replace(/^Note: /, "");
        h.element.setAttribute("role", "note");
        h.element.setAttribute(
          "aria-label",
          `Highlighted text: "${highlightedText}"`
        );
        return { mark: h, element: h.element, listeners: [cb] };
      };

      const boundEventListenerToHighlight = (h, data, className, cfiRange) => {
        h.element.setAttribute("ref", className);
        h.element.addEventListener("click", (e) => {
          onHighlightClick(e, data, isAnnotatorBarOpen);
          handleClickOnNestedElement(e);
        });
        h.element.addEventListener("touchstart", (e) => {
          onHighlightClick(e, data, isAnnotatorBarOpen);
          handleClickOnNestedElement(e);
        });
        h.element.addEventListener("keydown", (e) => {
          if (
            e.key === "ArrowDown" ||
            e.key === "ArrowUp" ||
            e.key === "ArrowLeft" ||
            e.key === "ArrowRight"
          ) {
            const textLayerElement =
              ref.current.parentElement.getElementsByClassName("textLayer")[0];
            let range = cfiRange.toRange(textLayerElement);
            if (!range) return; //someitmes happens when unmounting, needs to not render

            // Create new range
            const textNode = range.startContainer;
            let textElement = textNode.parentElement;
            const elementWindow =
              textNode.ownerDocument.defaultView ||
              textNode.ownerDocument.parentWindow;
            const selection = elementWindow.getSelection();
            // create new range for caret
            const range2 = elementWindow.document.createRange();
            range2.selectNode(textNode);
            if (["ArrowLeft", "ArrowUp"].includes(event.key)) {
              //start
              range2.setStart(textNode, range.startOffset);
            } else {
              //end
              textElement = range.endContainer.parentElement;
              range2.setStart(range.endContainer, range.endOffset);
            }
            range2.collapse(true); // Collapse to start

            textElement.contentEditable = true;
            textElement.focus();
            selection.removeAllRanges();
            selection.addRange(range2);
            requestAnimationFrame(() => {
              textElement.contentEditable = false;
              if (selection.rangeCount === 0) {
                selection.addRange(range2);
              }
            });
          }

          if (e.key === "Enter") {
            const ce = {};
            for (const prop in e) {
              if (typeof e[prop] !== "function") {
                ce[prop] = e[prop];
              }
            }
            ce.detail = [data];
            ce.target = h;
            onHighlightClick(ce, data, isAnnotatorBarOpen);
            handleClickOnNestedElement(ce);
            e.stopPropagation();
          }
        });
      };

      if (pane && rendered) {
        for (const annotation of [...hlMarks, ...underlineMarks]) {
          if (!annotation) continue;
          pane.removeMark(annotation.mark);
        }

        // render underlines from redux
        const unerlineElements = pageUnderlines.map((underline) => {
          const cfi = new PdfCFI(underline.cfi);

          return mark(
            MarkTypes.Underline,
            cfi,
            {},
            () => {},
            "underlineClass",
            {
              stroke: "none",
              "z-index": 10,
              "fill-opacity": 0.8,
              fill: "#333333"
            }
          );
        });
        // render highlights from redux
        let highlightElements = [];
        pageHighlights.forEach((highlight) => {
          const { color, interaction_type } = highlight;
          const cfi = new PdfCFI(highlight.cfi);
          if (interaction_type === INTERACTION_TYPES.CONTAINER) {
            const container = mark(
              MarkTypes.Thread,
              cfi,
              { id: highlight.id },
              populateThreadsIds,
              MarkTypes.Thread,
              {
                "z-index": 15,
                "mix-blend-mode": "multiply",
                "fill-opacity": 0.8,
                fill: "rgba(0, 0, 0, 0.12)",
                tabindex: 0
              }
            );

            highlightElements.push(container);
            scrollToHighlightIfNeeded(highlight.id);
          } else {
            const highlightItem = mark(
              MarkTypes.Highlight,
              cfi,
              { id: highlight.id },
              () => {},
              "highlightClass",
              darkMode
                ? {
                    "z-index": 1,
                    "mix-blend-mode": "normal",
                    "fill-opacity": 0.6,
                    fill: getHighlightColor(color, darkMode),
                    tabindex: 0,
                    "force-text-color": "black" // Add this attribute for reference
                  }
                : {
                    "z-index":
                      interaction_type === INTERACTION_TYPES.SUGGESTION
                        ? 5
                        : 14,
                    "mix-blend-mode": "multiply",
                    "fill-opacity":
                      interaction_type === INTERACTION_TYPES.SUGGESTION
                        ? 0.6
                        : 0.8,
                    fill: getHighlightColor(color, darkMode),
                    tabindex: 0
                  }
            );

            highlightElements.push(highlightItem);
            scrollToHighlightIfNeeded(highlight.id);
          }
        });

        setHlMarks(highlightElements);
        setUnderlineMarks(unerlineElements);
      }
    },
    //we need to redraw highlifhts when highlight or underlines change, when pane isinted and rendered and when position changes
    [
      darkMode,
      JSON.stringify(pageHighlights),
      JSON.stringify(pageUnderlines),
      pane,
      rendered,
      top,
      left,
      width,
      height
    ]
  );

  function scrollToHighlightIfNeeded(highlight) {
    if (highlight === shouldShowLocation) {
      scrollAnnotationIntoView(shouldShowLocation);
      dispatch(setShouldShowLocation(null));
    }
  }
  function populateThreadsIds(data) {
    let threadIds = handleClickOnThreadHighlight(data);
    dispatchSelectedThreads(threadIds);
  }

  const handleClickOnThreadHighlight = useCallback((e) => {
    const comments = e.detail;
    let threadIds = comments.map((comment) => comment.id);
    threadIds = [...new Set(threadIds)];
    return threadIds;
  }, []);

  function handleClickOnNestedElement(data) {
    return handleClickOnHighlightElement(data);
  }
  const handleClickOnHighlightElement = useCallback(
    (e) => {
      const elements = e.detail;
      const filteredElements = elements.filter(
        (element) =>
          !suggestions.some((suggestion) => suggestion.id === element.id)
      );

      return onHighlightClick(e, filteredElements[0], isAnnotatorBarOpen);
    },
    [onHighlightClick, suggestions]
  );

  function dispatchSelectedThreads(threadIds) {
    if (threadIds.length !== 1) {
      dispatch(setSelectedRealtimeInteractions(threadIds));
      if (!isSelectedThreads)
        dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.SELECTED_THREADS));
    } else {
      dispatch(setSelectedThreadId(threadIds[0].toString()));
      if (!isSingleThread)
        dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.SINGLE_THREAD));
    }
  }

  return (
    <div
      ref={ref}
      style={{ pointerEvents: "none" }}
      className={clsx(
        darkMode ? classes.darkModeHighlightLayer : classes.highlightLayer,
        "highlightLayer"
      )}></div>
  );
};

HighlightLayer.propTypes = {
  selectedLocation: PropTypes.shape(PdfPosition),
  highlights: PropTypes.arrayOf(PropTypes.shape(PdfHighlight)),
  underlines: PropTypes.arrayOf(PropTypes.shape(PdfHighlight)),
  onHighlightClick: PropTypes.func.isRequired,
  rendered: PropTypes.number,
  pageNumber: PropTypes.number
};

export default HighlightLayer;

import React, { useState, useEffect, useCallback } from "react";

export const useLiveAnnouncer = () => {
  const [announcement, setAnnouncement] = useState("");

  const announce = useCallback((message, priority = "polite") => {
    let announcer = document.getElementById("live-announcer");
    if (!announcer) {
      const style = document.createElement("style");
      style.textContent = `
        .sr-only {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
          border: 0;
          -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
        }
      `;
      document.head.appendChild(style);

      announcer = document.createElement("div");
      announcer.id = "live-announcer";
      announcer.setAttribute("aria-live", priority);
      announcer.setAttribute("role", "alert"); // Added role="alert"
      announcer.setAttribute("aria-atomic", "true");
      announcer.className = "sr-only";
      document.body.appendChild(announcer);
    }

    // Clear and update in separate frames
    announcer.textContent = "";

    // Use both aria-live and speech synthesis
    requestAnimationFrame(() => {
      announcer.textContent = message;

      // Fallback to speech synthesis if available
      if ("speechSynthesis" in window) {
        // Cancel any ongoing speech
        window.speechSynthesis.cancel();

        const utterance = new SpeechSynthesisUtterance(message);
        window.speechSynthesis.speak(utterance);
      }
    });

    setAnnouncement(message);
  }, []);

  useEffect(() => {
    return () => {
      const announcer = document.getElementById("live-announcer");
      if (announcer) {
        announcer.remove();
      }
      const style = document.querySelector("style");
      if (style && style.textContent.includes("sr-only")) {
        style.remove();
      }

      // Clean up any ongoing speech
      if ("speechSynthesis" in window) {
        window.speechSynthesis.cancel();
      }
    };
  }, []);

  return { announce, announcement };
};

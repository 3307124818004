// Dependencies
import React, { useState, useRef } from "react";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import {
  setHighlightColor,
  setShowHighlights,
  setAnnotatorMode,
  setShowAllHighlights
} from "../../redux/readerActionsSlice";
import { setForceHighlight } from "../../redux/grSlice";
import { setProfile } from "../../redux/userSlice";
import { isPdfSelector } from "../../redux/textsSlice";

import makeStyles from "@mui/styles/makeStyles";
import BorderColor from "@mui/icons-material/BorderColor";
import SizeIcon from "@mui/icons-material/FormatSize";
import DoneIcon from "@mui/icons-material/Done";
import {
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from "@mui/material";

import { useQuery, useStepStage } from "../../hooks";
import { selectSubmission, selectTask } from "../../redux/tasksSlice";
import HeaderButtonConstructor from "../SharedComponents/HeadeButtonConstructor";
const useStyles = makeStyles((theme) => ({
  left: {
    textAlign: "left"
  },
  listItem: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    cursor: "pointer",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },

  colorBtnLtr: {
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(1)
  },
  selectedColorBtn: {
    border: "2px solid black"
  },
  colorBtn: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    cursor: "pointer",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: "12px"
  },
  actionBox: {
    display: "inline-block"
  },
  pdfActionBox: {},
  menu: {},
  menuLtr: {
    justifyContent: "left",
    direction: "ltr",
    textAlign: "left"
  },
  menuItem: {
    fontSize: "14px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "0.1px",
    lineHeight: "22px"
  },
  lightIcon: {
    color: "#FFFFFF",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    }
  }
}));

export default function HeaderToolbar() {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const { submission_id } = useQuery();
  const userProfile = useSelector((state) => state.user.userProfile);

  // Redux state
  const [step] = useStepStage();
  const isPdf = useSelector(isPdfSelector);
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector((state) => selectTask(state, submission.task_id));
  const fontSize = userProfile.fontSize;
  const showHighlights = useSelector(
    (state) => state.readerActions.persistentActionState.showHighlights
  );
  const annotatorMode = useSelector(
    (state) => state.readerActions.persistentActionState.annotatorMode
  );
  const showAllHighlights = useSelector(
    (state) => state.readerActions.persistentActionState.showAllHighlights
  );
  const action = useSelector(
    (state) => state.readerActions.persistentActionState.actionBar
  );
  const highlightColor = useSelector(
    (state) => state.readerActions.persistentActionState.highlightColor
  );
  const textMenu = useSelector((state) => state.readerActions.showTextMenu);

  // Ephemeral state
  const [isOpen, setIsOpen] = useState(false); // this is the highlight color menu
  const anchorRef = useRef(null);

  // Derived State

  // Variables
  let colors = [
    {
      color: "#BEF3BF",
      text: "colors.green",
      default: "Green",
      hlColor: "#BEF3BF"
    },
    {
      color: "#B9E7FF",
      text: "colors.cyan",
      default: "Cyan",
      hlColor: "#B9E7FF"
    },
    {
      color: "#ECC5FF",
      text: "colors.pink",
      default: "Pink",
      hlColor: "#ECC5FF"
    },
    {
      color: "#FF7F74",
      text: "colors.red",
      default: "Red",
      hlColor: "#FF7F74"
    },
    {
      color: "#FFE690",
      text: "colors.yellow",
      default: "Yellow",
      hlColor: "#FFE690"
    }
  ];
  const isPeerReview = task.task_type === "peerReview";

  // Behavior
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setIsOpen(false);
    } else if (event.key === "Escape") {
      setIsOpen(false);
    }
  }

  // Render
  return (
    <Box
      className={clsx(
        classes.actionBox,
        classes.actionBoxLt,
        isPdf && classes.pdfActionBox
      )}
      direction={"auto"}>
      {textMenu && !isPeerReview && (
        <HeaderButtonConstructor
          ref={anchorRef}
          intlStringId="menu.tooltip.highlighter"
          defaultMessage="Highlight menu"
          placement="left"
          iconLabel="Highlight menu"
          className={clsx(classes.lightIcon)}
          handleClick={handleClick}
          icon={<BorderColor />}
        />
      )}

      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role="menu"
        placement="bottom-end"
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper elevation={6}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={isOpen}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}>
                  {action === "" && (
                    <MenuItem
                      className={clsx(classes.menuItem, classes.left)}
                      onClick={() => {
                        dispatch(
                          setAnnotatorMode(
                            annotatorMode === "highlight" ? "" : "highlight"
                          )
                        );
                        handleClose();
                      }}>
                      <ListItemIcon
                        className={classes.listItem}
                        role="switch" //TODO: change this element to a checkbox
                        aria-checked={annotatorMode === "highlight"}
                        aria-labelledby="highlightModeLabel">
                        {annotatorMode === "highlight" && <DoneIcon />}
                      </ListItemIcon>

                      <Typography variant="inherit" id="highlightModeLabel">
                        <FormattedMessage
                          id="highlight.highlightMode"
                          defaultMessage="Highlight Mode"
                        />
                      </Typography>
                    </MenuItem>
                  )}
                  {action === "" && (
                    <MenuItem
                      className={clsx(classes.menuItem, classes.left)}
                      onClick={() => {
                        if (annotatorMode === "poc" && step !== "highlight") {
                          dispatch(setForceHighlight(!showHighlights));
                        } else {
                          dispatch(setShowHighlights(!showHighlights));
                        }
                        handleClose();
                      }}
                      role="switch" //TODO: change this element to a checkbox
                      aria-checked={showHighlights}
                      aria-label="Show highlights">
                      <ListItemIcon
                        className={classes.listItem}
                        role="switch" //TODO: change this element to a checkbox
                        aria-checked={showHighlights}
                        aria-label="Show highlights">
                        {showHighlights && <DoneIcon />}
                      </ListItemIcon>
                      <Typography variant="inherit" id="showHighlightsLabel">
                        <FormattedMessage
                          id="highlight.showMode"
                          defaultMessage="Show highlights"
                        />
                      </Typography>
                    </MenuItem>
                  )}
                  {action === "" && <Divider />}
                  {colors.map((item) => {
                    return (
                      <MenuItem
                        key={item.color}
                        className={clsx(classes.menuItem, classes.left)}
                        onClick={() => {
                          dispatch(setHighlightColor(item.hlColor));
                          handleClose();
                        }}>
                        <ListItemIcon>
                          <Box
                            className={clsx(
                              classes.colorBtn,
                              classes.colorBtnLtr,
                              highlightColor === item.hlColor &&
                                classes.selectedColorBtn
                            )}
                            style={{
                              backgroundColor: item.color
                            }}
                            role="checkbox" //FIXME: This should really be a radiogrop
                            aria-checked={highlightColor === item.Color}
                            aria-label={`${item.text} label`}
                          />
                        </ListItemIcon>
                        <Typography
                          variant="inherit"
                          id={`${item.text} label`}
                          noWrap>
                          <FormattedMessage
                            id={item.text}
                            defaultMessage={item.default}
                          />
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {textMenu && !isPdf && (
        <HeaderButtonConstructor
          intlStringId="menu.tooltip.fontSize"
          defaultMessage="Text size"
          placement="bottom"
          iconLabel="Text size"
          className={clsx(classes.lightIcon)}
          handleClick={() => {
            let fontSizeInt = parseInt(fontSize);
            let nextFontSize = 5;
            if (fontSizeInt < 8) nextFontSize = fontSizeInt + 1;
            dispatch(
              setProfile({
                ...userProfile,
                fontSize: nextFontSize.toString()
              })
            );
          }}
          icon={<SizeIcon />}
        />
      )}
    </Box>
  );
}

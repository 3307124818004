// Dependencies
import React, { useEffect, useState } from "react";
import { httpCallables, firestore } from "../../firebase";
import { useQuery, useStepStage } from "../../hooks";
import { captureException } from "../../utils/errorHandlers";
import clsx from "clsx";
import { motion } from "framer-motion";
import { doc, updateDoc } from "firebase/firestore";

// Redux
import { fetchConversation } from "../../redux/chatSlice";
import { useFirestoreConnect } from "../../hooks/useFirestoreConnect";
import { useDispatch, useSelector } from "react-redux";
import { setInteractions } from "../../redux/interactionsSlice";
import { selectCurrentText, setSelectedTextId } from "../../redux/textsSlice";
import {
  setAction,
  setAnnotatorMode,
  setBreadcrumbs,
  ToggleReaderMode
} from "../../redux/readerActionsSlice";
import { selectIsImpersonation } from "../../redux/userSlice";
// Components
import { SecondarySidebarLayout } from "../SharedComponents";
import CommentPanel from "../comments/CommentPanel";
import { PangeaSpinner } from "../SharedComponents";
import PDFThumbBar from "./pdf/sidebar/PDFThumbBar";
import { initializeFeatureLayout } from "../../redux/layoutSlice";
import Sidebar from "../../Sidebar";

import {
  selectIsComments,
  selectIsThumbnails,
  selectIsPrimarySidebar,
  selectSecondarySidebarCollapsed,
  selectPrimarySidebarState,
  selectSecondarySidebarState
} from "../../redux/firestoreSelectors";
import { FEATURES } from "../../consts";
import { resetThreadsState } from "../../redux/realtimeInteractionsSlice";
import StandardSidebar from "../Sidebar/StandardSidebar";

// Material UI
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { selectCourseByTextId } from "../../redux/coursesSlice";
import ReaderContent from "./ReaderContent";

// Styles
const useStyles = makeStyles(() => {
  return {
    readerViewContainer: {
      position: "relative",
      flex: 1,
      height: "100%",
      justifyContent: "center"
    },
    innerWrapper: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0
    },
    dirLtr: {
      direction: "ltr"
    }
  };
});

function Reader() {
  // Hooks
  const dispatch = useDispatch();
  const { text_id } = useQuery();
  const classes = useStyles();

  // Redux Selectors
  const userId = useSelector((state) => state.firebase.auth.uid);

  const PrimarySidebarWidth = useSelector(
    (state) => state.layout.primarySidebarWidth
  );
  const SecondarySidebarWidth = useSelector(
    (state) => state.layout.secondarySidebarWidth
  );

  useEffect(() => {
    dispatch(fetchConversation({ submission_id: null, text_id: text_id }));
  }, [dispatch, text_id]);

  useFirestoreConnect([
    {
      collection: "userProfiles",
      doc: "customConfig",
      subcollections: [{ collection: userId, doc: FEATURES.READER }],
      storeAs: "customConfig"
      // type: "once"
    }
  ]);

  const primarySidebarState = useSelector((state) =>
    selectPrimarySidebarState(state)
  );
  const secondarySidebarState = useSelector((state) =>
    selectSecondarySidebarState(state)
  );
  const isPrimarySidebar = useSelector((state) =>
    selectIsPrimarySidebar(state)
  );
  const isSecondarySidebar = useSelector((state) =>
    selectSecondarySidebarCollapsed(state)
  );
  const isImpersonation = useSelector(selectIsImpersonation);

  const action = useSelector(
    (state) => state.readerActions.persistentActionState.actionBar
  );
  const grMode = useSelector((state) => state.gr.mode);
  const [step] = useStepStage();
  const annotatorMode = useSelector(
    (state) => state.readerActions.persistentActionState.annotatorMode
  );
  // Ephermeral state
  const [isLoading, setIsLoading] = useState(false);
  // Derived state
  // const isSidebarVisible = action !== "";
  const isThumbnails = useSelector((state) => selectIsThumbnails(state));
  const isComments = useSelector((state) => selectIsComments(state));
  const disableSeconderySidebar =
    ["poc", "", "task"].includes(action) &&
    grMode === "light" &&
    ["review", "answer"].includes(step);

  const course = useSelector(selectCourseByTextId);
  const selectedText = useSelector(selectCurrentText);

  //Derived state
  const author = selectedText && selectedText.author;
  const textTitle = selectedText && selectedText.name;

  // Behavior
  useEffect(() => {
    // if states are inverted ->
    // action == "poc" && primary sidebar == collapsed
    // This means we need to revert the reader mode to guided_reading and sidebar expanded.
    if (!isPrimarySidebar && action === "poc") {
      dispatch(ToggleReaderMode());
    }
  }, [dispatch, isPrimarySidebar, action]);

  useEffect(() => {
    dispatch(initializeFeatureLayout());
  }, [dispatch, primarySidebarState, secondarySidebarState]);

  //hack: 9/22/22 this is a band aid for a bug where annotator grMode inits as false, we’re separating ‘’ as a legit value ..whereas false || undefined are not.
  // A better fix would be to get rid of the actions / annotatorMode couple and have a seperate attr for highlight grMode
  useEffect(() => {
    if (action === "task") {
      dispatch(setAnnotatorMode("poc"));
      dispatch(setAction("poc"));
    }
    if (!annotatorMode && annotatorMode !== "")
      dispatch(setAnnotatorMode(action));
  }, [action, annotatorMode, dispatch]);

  useEffect(() => {
    // Set selected text_id in firestore
    if (!text_id) return;
    dispatch(setSelectedTextId(Number(text_id)));
    const docRef = doc(firestore, `users/${userId}`);
    !isImpersonation && updateDoc(docRef, { selectedTextId: Number(text_id) });
  }, [text_id, dispatch, userId, isImpersonation]);

  useEffect(() => {
    // Get text related interactions
    if (!text_id) return;
    let isCancelled = false;

    try {
      setIsLoading(true);
      httpCallables
        .interactionFunctions({
          text_id: Number(text_id),
          func_name: "readUserInteractionsPerText"
        })
        .then(({ data }) => {
          const { success } = data;
          if (success && !isCancelled) {
            const { interactions } = JSON.parse(data.payload);
            dispatch(setInteractions(interactions));
          } else {
            if (!isCancelled) {
              let error = new Error(`READ_TEXT_INTERACTIONS_FAILED`);
              captureException(error, {
                readUserInteractionsPerTextRetVal: JSON.stringify(data)
              });
            }
          }
        })
        .finally(() => setIsLoading(false));
    } catch (err) {
      captureException(err, { text_id });
    }

    return () => {
      isCancelled = true;
      dispatch(resetThreadsState());
    };
  }, [dispatch, text_id]);

  useEffect(() => {
    const parts = [];
    parts.push({
      text: course.name,
      url: `/library?course_id=${course.id}`,
      brightText: false
    });
    parts.push({
      url: `/library?course_id=${course.id}`,
      text: author + " - " + textTitle,
      brightText: false
    });

    dispatch(
      setBreadcrumbs({
        breadcrumbs: parts,
        blue: false,
        showTextMenu: true
      })
    );
  }, [course.name, course.id, author, textTitle]);

  if (isLoading) return <PangeaSpinner />;
  else
    return (
      <>
        <Sidebar>{action !== "" && <StandardSidebar />}</Sidebar>
        <Box
          className={clsx(classes.readerViewContainer, classes.dirLtr)}
          style={{
            width: `calc(100vw - ${PrimarySidebarWidth}px - ${SecondarySidebarWidth}px)`
          }}>
          <motion.div
            className={classes.innerWrapper}
            animate={{
              zIndex: 0,
              opacity: 1
              // transitionEnd: {
              //   zIndex: !showBook && -1
              // }
            }}
            style={{
              pointerEvents: "all"
            }}
            transition={{ duration: 0.5 }}>
            <ReaderContent />
          </motion.div>
        </Box>
        {step === "highlight" ? ( // only show the secoundary sidebar when the reader is on view
          <SecondarySidebarLayout
            collapsed={!isSecondarySidebar || disableSeconderySidebar}>
            {isThumbnails && <PDFThumbBar />}
            {isComments && <CommentPanel />}
          </SecondarySidebarLayout>
        ) : (
          <></>
        )}
      </>
    );
}

Reader.propTypes = {};

export default Reader;

// Utils

export const handleRichText = (richText) => {
  if (!richText) {
    return "";
  } else if (!richText.blocks || !Array.isArray(richText.blocks)) {
    return richText;
  }

  return richText.blocks.map((block) => `<p>${block.text}</p>`).join("");
};

import { useEffect, useCallback } from "react";

function useConstrainedCaret(containerRef) {
  const isValidNode = useCallback((node) => {
    // Only accept text nodes with content
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent.trim().length > 0;
    }

    // For elements, only accept specific leaf elements that should be selectable
    if (node.nodeType === Node.ELEMENT_NODE) {
      const selectableElements = ["IMG", "INPUT", "BUTTON", "A"];
      return (
        selectableElements.includes(node.tagName) && node.children.length === 0
      );
    }

    return false;
  }, []);

  const makeTextNodesNavigable = useCallback(() => {
    if (!containerRef.current) return;

    const walker = document.createTreeWalker(
      containerRef.current,
      NodeFilter.SHOW_TEXT,
      {
        acceptNode: (node) => {
          if (node.textContent.trim().length === 0)
            return NodeFilter.FILTER_REJECT;
          const parentElement = node.parentElement;
          if (
            parentElement &&
            (parentElement.tagName === "A" ||
              parentElement.hasAttribute("data-text-block"))
          ) {
            return NodeFilter.FILTER_REJECT;
          }
          return NodeFilter.FILTER_ACCEPT;
        }
      }
    );

    const nodes = [];
    let node;
    while ((node = walker.nextNode())) {
      nodes.push(node);
    }

    nodes.reverse().forEach((textNode) => {
      const wrapper = document.createElement("span");
      wrapper.setAttribute("data-text-block", "true");
      wrapper.setAttribute("tabindex", "0");
      textNode.parentNode.replaceChild(wrapper, textNode);
      wrapper.appendChild(textNode);
    });
  }, [containerRef]);

  useEffect(() => {
    makeTextNodesNavigable();
  }, [makeTextNodesNavigable]);

  const isNodeInContainer = useCallback(
    (node) => {
      if (!containerRef.current) return false;
      return containerRef.current.contains(node);
    },
    [containerRef]
  );

  const findNextNode = useCallback(
    (currentNode, forward = true) => {
      if (!containerRef.current) return null;

      const walker = document.createTreeWalker(
        containerRef.current,
        NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT,
        {
          acceptNode: (node) => {
            if (node === currentNode) {
              return NodeFilter.FILTER_SKIP;
            }
            return isValidNode(node)
              ? NodeFilter.FILTER_ACCEPT
              : NodeFilter.FILTER_SKIP;
          }
        }
      );

      walker.currentNode = currentNode;
      return forward ? walker.nextNode() : walker.previousNode();
    },
    [isValidNode, containerRef]
  );

  const findNodeVertically = useCallback(
    (currentNode, goDown = true) => {
      if (!containerRef.current) return null;

      const selection = window.getSelection();
      if (!selection.rangeCount) return null;

      const currentRange = selection.getRangeAt(0);
      const currentRect = currentRange.getBoundingClientRect();
      const targetX = currentRect.left;

      const walker = document.createTreeWalker(
        containerRef.current,
        NodeFilter.SHOW_TEXT,
        {
          acceptNode: (node) => {
            return isValidNode(node)
              ? NodeFilter.FILTER_ACCEPT
              : NodeFilter.FILTER_SKIP;
          }
        }
      );

      let node;
      let bestNode = null;
      let bestDistance = Infinity;

      while ((node = walker.nextNode()) !== null) {
        const range = document.createRange();
        range.selectNodeContents(node);
        const rect = range.getBoundingClientRect();

        const verticalDiff = goDown
          ? rect.top - currentRect.bottom
          : currentRect.top - rect.bottom;

        if (verticalDiff <= -10) continue;

        const horizontalDiff = Math.abs(rect.left - targetX);
        const totalDistance = verticalDiff * 2 + horizontalDiff;

        if (totalDistance < bestDistance) {
          bestDistance = totalDistance;
          bestNode = node;
        }
      }
      return bestNode;
    },
    [isValidNode, containerRef]
  );

  const scrollIntoViewIfNeeded = useCallback(
    (range) => {
      const rect = range.getBoundingClientRect();

      let scrollParent = containerRef.current;
      while (
        scrollParent &&
        getComputedStyle(scrollParent).overflowY === "visible" &&
        scrollParent !== document.body
      ) {
        scrollParent = scrollParent.parentElement;
      }

      if (!scrollParent) return;

      const scrollParentRect = scrollParent.getBoundingClientRect();
      const buffer = 50;

      if (rect.top < scrollParentRect.top + buffer) {
        scrollParent.scrollBy({
          top: rect.top - scrollParentRect.top - buffer,
          behavior: "smooth"
        });
      } else if (rect.bottom > scrollParentRect.bottom - buffer) {
        scrollParent.scrollBy({
          top: rect.bottom - scrollParentRect.bottom + buffer,
          behavior: "smooth"
        });
      }
    },
    [containerRef]
  );

  const scrollToNextPage = useCallback(() => {
    if (!containerRef.current) return;

    let scrollParent = containerRef.current;
    while (
      scrollParent &&
      getComputedStyle(scrollParent).overflowY === "visible" &&
      scrollParent !== document.body
    ) {
      scrollParent = scrollParent.parentElement;
    }

    if (!scrollParent) return;

    const scrollAmount = scrollParent.clientHeight * 0.9;
    scrollParent.scrollBy({
      top: scrollAmount,
      behavior: "smooth"
    });
  }, [containerRef]);

  const simulateRightArrow = useCallback(() => {
    const event = new KeyboardEvent("keydown", {
      key: "ArrowRight",
      bubbles: true,
      cancelable: true
    });
    document.dispatchEvent(event);
  }, []);

  const handleKeyDown = useCallback(
    (e) => {
      if (
        !["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].includes(e.key)
      ) {
        return;
      }
      const selection = window.getSelection();
      if (!selection.rangeCount) return;

      const startNode = selection.anchorNode;
      const startOffset = selection.anchorOffset;
      const currentNode = selection.focusNode;
      const endNodeOffset = selection.focusOffset;

      if (!currentNode || !isNodeInContainer(currentNode)) return;

      let nextNode = null;
      let nextOffset = 0;
      let setStart = e.shiftKey;

      switch (e.key) {
        case "ArrowLeft":
          if (selection.focusOffset > 0) return;
          nextNode = findNextNode(currentNode, false);
          if (!nextNode || nextNode === currentNode) return;
          nextOffset = nextNode?.textContent?.length || 0;
          setStart = false;
          break;

        case "ArrowRight":
          if (selection.focusOffset < (currentNode.textContent?.length || 0))
            return;
          nextNode = findNextNode(currentNode, true);
          nextOffset = 0;
          break;

        case "ArrowUp":
          nextNode = findNodeVertically(currentNode, false);
          setStart = false;
          break;

        case "ArrowDown":
          nextNode = findNodeVertically(currentNode, true);
          break;
      }

      if (nextNode && isNodeInContainer(nextNode)) {
        e.preventDefault();
        const range = document.createRange();
        if (setStart) {
          range.setStart(startNode, startOffset);
          range.setEnd(nextNode, nextOffset);
        } else {
          range.setStart(nextNode, nextOffset);
          range.setEnd(currentNode, endNodeOffset);
        }

        selection.removeAllRanges();
        selection.addRange(range);

        scrollIntoViewIfNeeded(range);
      }
    },
    [
      findNextNode,
      findNodeVertically,
      isNodeInContainer,
      scrollIntoViewIfNeeded
    ]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return {
    scrollToNextPage,
    simulateRightArrow
  };
}

export default useConstrainedCaret;

// Dependancies
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { useLayer, Arrow } from "react-laag";
import { useIntl } from "react-intl";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { isEmpty } from "lodash-es";
import { useRendition } from "../../RenditionContext";
import {
  COMMENT_PANEL_VIEW,
  INTERACTION_SUBTYPES,
  INTERACTION_TYPES,
  SECONDARY_SIDEBAR_STATE
} from "../../consts";
import { useOnClickOutside, useStepStage } from "../../hooks";

//Redux
import {
  selectIsComments,
  selectIsSuggestions
} from "../../redux/firestoreSelectors";
import { useSelector, useDispatch } from "react-redux";
import {
  setSecondarySidebarViewMode,
  suggestionsIconClicked
} from "../../redux/layoutSlice";
import { setCommentPanelState } from "../../redux/realtimeInteractionsSlice";
import { clearSelection } from "../reader/utils";
import {
  closeAnnotatorBar,
  selectSelectedHighlight,
  toggleNoQuestionsMessage
} from "../../redux/highlightSlice";
import {
  setSelectedTempHighlight,
  addNewQuestion,
  removeTempHighlight
} from "../../redux/interactionsSlice";
import { addSnackbar } from "../../redux/snackbarSlice";

// Components
import ReaderAction from "./ReaderAction";
import HighlightColor from "./HighlightColor";
import RichTooltip from "../SharedComponents/RichTooltip";

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import { Box, Divider } from "@mui/material";
import BorderColor from "@mui/icons-material/BorderColor";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddLinkIcon from "@mui/icons-material/AddLink";

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    position: "relative"
  },
  readerActions: {
    display: "flex",
    alignItems: "center",
    zIndex: 1500,
    padding: 1,
    borderRadius: 4,
    paddingInline: theme.spacing(1),
    backgroundColor: "#333333",
    color: "#fafafa",
    height: 48
  },
  highlightAction: {
    "& :hover": {
      color: "#BEF3BF"
    }
  },
  divider: {
    color: "#757575",
    backgroundColor: "#757575",
    margin: theme.spacing(1)
  }
}));

export default function ReactReaderActions({
  addHighlight,
  minimal,
  children,
  deleteHighlight = false,
  canComment = false
}) {
  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  let highlightRef = useRef(null);
  let containerRef = useRef();
  const { rendition } = useRendition();

  // Redux state

  const annotatorMode = useSelector(
    (state) => state.readerActions.persistentActionState.annotatorMode
  );

  const clientRectangle = useSelector(
    (state) => state.highlighter.clientRectangle
  );

  const selectedHighlight = useSelector((state) =>
    selectSelectedHighlight(state)
  );
  const highlight_element = useSelector(
    (state) => state.highlighter.selectedHighlight
  );

  // This refers to the text selection that the user marked on the source text
  const selectedText = useSelector((state) => state.highlighter.selectedText);

  const isAnnotatorBarOpen = useSelector(
    (state) => state.highlighter.isAnnotatorBarOpen
  );
  const minimalFromState = useSelector((state) => state.highlighter.isMinimal);

  const isClickOnMark = useSelector((state) => state.highlighter.isClickOnMark);
  const isComments = useSelector((state) => selectIsComments(state));
  const isSuggestions = useSelector((state) => selectIsSuggestions(state));

  const detachedComment = useSelector(
    (state) => state.readerActions.detachedComment
  );
  const commentPanelState = useSelector(
    (state) => state.realtimeInteractions.commentPanelState
  );
  const selectedTempHighlight = useSelector(
    (state) => state.interactions.selectedTempHighlight
  );
  const selectedQuestionId = useSelector(
    (state) => state.interactions.selectedInteractionId
  );
  const noQuestionsMessageOpen = useSelector(
    (state) => state.highlighter.noQuestionsMessageOpen
  );
  const questions = useSelector((state) => state.interactions.questions);
  const [step] = useStepStage();

  // Ephemeral state
  const [isOpen, setIsOpen] = useState(false);
  const [actionMode, setActionMode] = useState("highlight");

  // Derived state
  const isMinimal = minimal || minimalFromState;

  const colors = [
    {
      color: "#BEF3BF",
      text: "reader.toolbar.colors.green",
      hlColor: "#BEF3BF"
    },
    {
      color: "#B9E7FF",
      text: "reader.toolbar.colors.blue",
      hlColor: "#B9E7FF"
    },
    {
      color: "#ECC5FF",
      text: "reader.toolbar.colors.pink",
      hlColor: "#ECC5FF"
    },
    {
      color: "#FF7F74",
      text: "reader.toolbar.colors.red",
      hlColor: "#FF7F74"
    },
    {
      color: "#FFE690",
      text: "reader.toolbar.colors.yellow",
      hlColor: "#FFE690"
    }
  ];
  const showSocialAction =
    ["poc", "", "highlight"].includes(annotatorMode) && isClickOnMark;
  const showHighlightColors =
    !isMinimal && isClickOnMark && !!selectedHighlight?.interaction_id;

  //Behavior

  function adjustHighlightedRect(highlightedRect) {
    const currentRect = { ...highlightedRect };

    if (currentRect["top"] < 50 && currentRect["bottom"] > 200) {
      currentRect["top"] = 100;
      currentRect["y"] = currentRect["top"];
      currentRect["height"] = currentRect["bottom"] - currentRect["top"];
    }
    if (!currentRect.left) {
      // the highlight Rect isn't there / doesn't have the left property -
      // just take the bounding client rect of the whole document,
      // won't be shown anyway
      const newRect = document.documentElement.getBoundingClientRect();
      return newRect;
    }
    return currentRect;
  }

  useEffect(() => {
    clearSelection(rendition);
  }, [step]);

  const layerParams = {
    isOpen,
    overflowContainer: false,
    trigger: {
      getBounds: () => adjustHighlightedRect(clientRectangle)
    },
    container: containerRef.current,
    // DON'T REMOVE USEFULL FOR DEBUGGING
    // placement: "center"
    // placement: "top-center",
    possiblePlacements: ["top-center", "bottom-center"],
    preferY: "top",
    auto: true,
    arrowOffset: 8,
    containerOffset: 8,
    triggerOffset: 12
  };

  const { layerProps, renderLayer, arrowProps } = useLayer(layerParams);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y !== prevPos.y) {
      setIsOpen(false);
    }
  });
  useLayoutEffect(() => {
    if (
      !detachedComment &&
      ((isAnnotatorBarOpen &&
        !isOpen &&
        (!annotatorMode ||
          isClickOnMark ||
          (annotatorMode === "poc" && !selectedQuestionId))) ||
        noQuestionsMessageOpen)
    ) {
      setActionMode(
        questions.length || !addHighlight ? "highlight" : "tooltip"
      );
      setIsOpen(true);
    } else {
      if (!isAnnotatorBarOpen) {
        setIsOpen(false);
        if (
          !selectedQuestionId &&
          selectedHighlight &&
          !selectedHighlight?.interaction_id &&
          !selectedTempHighlight &&
          commentPanelState !== COMMENT_PANEL_VIEW.NEW_COMMENT
        ) {
          cancelTempHighlight();
        }
      }
    }
  }, [
    annotatorMode,
    dispatch,
    isAnnotatorBarOpen,
    isClickOnMark,
    isOpen,
    selectedQuestionId,
    noQuestionsMessageOpen
  ]);

  async function handleMarkAction() {
    dispatch(closeAnnotatorBar());
    clearSelection(rendition);
    if (isEmpty(selectedHighlight) && isClickOnMark)
      await deleteHighlight(highlight_element);
    else if (!isEmpty(selectedHighlight) && isClickOnMark) {
      await deleteHighlight(selectedHighlight);
    } else if (!isEmpty(selectedText)) {
      let newHighlight = { ...selectedText };
      newHighlight = Object.assign(newHighlight, {
        interaction_type: INTERACTION_TYPES.READER,
        interaction_subtype: INTERACTION_SUBTYPES.QUOTE
      });
      await addHighlight(newHighlight);
    }
  }

  const handleAdaptHighlight = () => {
    dispatch(setSelectedTempHighlight(selectedHighlight.id));
    dispatch(closeAnnotatorBar());
  };

  async function handleSocialAction() {
    if (isSuggestions) {
      dispatch(suggestionsIconClicked(SECONDARY_SIDEBAR_STATE.COLLAPSED));
    }
    if (!isComments) {
      dispatch(setSecondarySidebarViewMode(SECONDARY_SIDEBAR_STATE.COMMENTS));
    }
    dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.NEW_COMMENT));
    dispatch(closeAnnotatorBar());
  }

  async function copySelectedText() {
    await navigator.clipboard.writeText(selectedHighlight.content);
    dispatch(closeAnnotatorBar());
    dispatch(
      addSnackbar({
        message: intl.formatMessage({
          id: "textCopied.success",
          defaultMessage: "Text copied successfully"
        })
      })
    );
    if (!selectedHighlight.interaction_id) {
      cancelTempHighlight();
    }
  }

  useOnClickOutside(containerRef, () => {
    if (isAnnotatorBarOpen) dispatch(closeAnnotatorBar());
  });

  const handleAddQuestionAction = () => {
    dispatch(addNewQuestion(true));
    clearSelection(rendition);
    dispatch(toggleNoQuestionsMessage());
  };

  const cancelTempHighlight = () => {
    dispatch(removeTempHighlight(selectedHighlight.id));
    clearSelection(rendition);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      dispatch(closeAnnotatorBar());
    }
  };

  return (
    <div
      ref={containerRef}
      className={classes.container}
      role="toolbar"
      onKeyDown={handleKeyDown}>
      {isOpen &&
        // DON'T REMOVE USEFULL FOR DEBUGGING
        renderLayer(
          // <Box {...layerProps}>
          //   <Box
          //     style={{
          //       height: clientRectangle.height,
          //       width: clientRectangle.width,
          //       background: "rgba(0,0,0,0.3)"
          //     }}
          //   />
          // </Box>
          <>
            {actionMode === "highlight" && (
              <Box
                {...layerProps}
                className={classes.readerActions}
                boxShadow={4}>
                {showHighlightColors && (
                  <>
                    {colors.map((item) => (
                      <HighlightColor
                        key={item.color}
                        color={item.color}
                        focus={selectedHighlight.color === item.color}
                        name={intl.formatMessage({
                          id: item.text,
                          defaultMessage: "Color"
                        })}
                      />
                    ))}
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                      flexItem
                    />
                  </>
                )}
                {!selectedHighlight?.interaction_id && !!addHighlight && (
                  <ReaderAction
                    minimal={isMinimal}
                    ref={highlightRef}
                    Icon={AddLinkIcon}
                    className={classes.highlightAction}
                    handleClick={handleAdaptHighlight}
                    intlStringId="menu.tooltip.adaptHighlight"
                    defaultMessage="Relate to a question"
                    placement="bottom"
                  />
                )}
                {(selectedHighlight?.interaction_id || !addHighlight) && (
                  <ReaderAction
                    minimal={isMinimal}
                    ref={highlightRef}
                    Icon={isClickOnMark ? DeleteOutlinedIcon : BorderColor}
                    className={classes.highlightAction}
                    handleClick={handleMarkAction}
                    intlStringId="menu.tooltip.deleteHighlight"
                    defaultMessage="Delete highlight"
                    placement="bottom"
                  />
                )}
                <ReaderAction
                  ref={highlightRef}
                  Icon={ContentCopyIcon}
                  className={classes.highlightAction}
                  handleClick={() => {
                    copySelectedText();
                  }}
                  intlStringId="menu.tooltip.copyText"
                  defaultMessage="Copy text"
                  placement="bottom"
                />
                {showSocialAction && canComment && (
                  <ReaderAction
                    ref={highlightRef}
                    Icon={CommentOutlinedIcon}
                    className={classes.highlightAction}
                    handleClick={handleSocialAction}
                    intlStringId="menu.tooltip.createComment"
                    defaultMessage="Create comment"
                    placement="bottom"
                  />
                )}
                <Arrow
                  {...arrowProps}
                  borderColor="#333333"
                  backgroundColor="#333333"
                />
              </Box>
            )}
            {actionMode === "tooltip" && (
              <RichTooltip
                ref={highlightRef}
                layerProps={layerProps}
                style={{ backgroundColor: "white" }}
                body="gr.selectionAddQuestion.message"
                action="gr.selectionAddQuestion.action"
                onActionClick={handleAddQuestionAction}
              />
            )}
          </>
        )}
      {React.Children.map(children, (child) => {
        const newChild = React.cloneElement(child, {
          container: containerRef
        }); // add props here
        return newChild;
      })}
    </div>
  );
}

ReactReaderActions.propTypes = {
  deleteHighlight: PropTypes.func,
  children: PropTypes.node,
  addHighlight: PropTypes.func,
  minimal: PropTypes.bool,
  canComment: PropTypes.bool
};

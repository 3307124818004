// PdfPageMemo.jsx
import React, { useEffect, useCallback } from "react";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { PdfPosition } from "./PdfTypes";
import { Page } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { captureException } from "../../../utils/errorHandlers";

import { useSelector } from "react-redux";
import { selectDarkMode } from "../../../redux/firestoreSelectors";

const useStyles = makeStyles((theme) => ({
  DarkModepage: {
    textAlign: "center",
    margin: "12px auto",
    position: "relative",
    "& canvas": {
      filter: ({ darkMode }) =>
        darkMode ? "invert(1) hue-rotate(180deg)" : "none"
    },
    "& .textLayer": {
      // Remove the opacity adjustment for dark mode to keep text crisp
      opacity: 1, // Changed from conditional opacity
      color: ({ darkMode }) => (darkMode ? "#ffffff" : "inherit"),
      "& span::selection": {
        background: ({ darkMode }) =>
          darkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)"
      }
    },
    "&::before": {
      content: '""',
      display: ({ darkMode }) => (darkMode ? "block" : "none"),
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgb(28, 27, 23)"
      // zIndex: -1
    }
  },
  page: {
    textAlign: "center",
    margin: "12px auto"
    /*  useful for debugging mismatch of text and highlights/ pdf 
   
   '& .textLayer span':{

        color:'black',
      }
    /* */
  }
}));
const PdfPageMemo = ({
  scale,
  width,
  pageNumber,
  renderMode,
  onLoadSuccess,
  onRenderedText,
  onRenderSuccess
}) => {
  const darkMode = useSelector((state) => selectDarkMode(state));
  const classes = useStyles({ darkMode });

  // Rest of the component remains the same
  const onRenderError = useCallback((err) => captureException(err), []);

  const onRenderTextLayerSuccess = useCallback(
    (textLayerRenderer, textParameters) => {
      if (renderMode === "canvas") {
        onRenderedText(new Date().getTime(), textLayerRenderer, textParameters);
      }
    },
    [onRenderedText, renderMode]
  );

  useEffect(() => {
    return () => onRenderedText(0);
  }, [onRenderedText]);

  function showSpinner() {
    return (
      <DotLottieReact
        src="/loading_book_lottie.json"
        mode="bounce"
        background="transparent"
        speed="1"
        style={{
          width: "300px",
          height: "300px",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)"
        }}
        loop
        autoplay></DotLottieReact>
    );
  }

  return (
    <Page
      scale={scale}
      renderMode={renderMode}
      loading={showSpinner}
      data-page-number={pageNumber}
      onLoadSuccess={onLoadSuccess}
      onRenderError={onRenderError}
      enhanceTextSelection={true}
      onRenderTextLayerSuccess={onRenderTextLayerSuccess}
      width={width}
      className={clsx(
        classes.page,
        `pdf-page-${pageNumber}`,
        darkMode && classes.DarkModepage
      )}
      pageIndex={pageNumber - 1}
      onRenderSuccess={onRenderSuccess}
    />
  );
};

PdfPageMemo.propTypes = {
  scale: PropTypes.number,
  width: PropTypes.number,
  location: PropTypes.shape(PdfPosition),
  interactive: PropTypes.bool,
  renderMode: PropTypes.oneOf(["svg", "canvas", "none"]),
  pageNumber: PropTypes.number,
  onLoadSuccess: PropTypes.func.isRequired,
  onRenderedText: PropTypes.func.isRequired,
  onRenderSuccess: PropTypes.func.isRequired
};

export default PdfPageMemo;

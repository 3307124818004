// Dependencies
import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl"; // We don't need both, use only the hook
import clsx from "clsx";
import { TASK } from "../../consts";
import { useQuery, useStepStage } from "../../hooks";

// Redux
import { useSelector } from "react-redux";
import { selectTask, selectSubmission } from "../../redux/tasksSlice";
import { selectText } from "../../redux/textsSlice";
import { selectQuestions } from "../../redux/interactionsSlice";

// Components
import StepsToggler from "./StepsToggler";
import Questions from "./Questions";

import makeStyles from "@mui/styles/makeStyles";
import { Paper, Box, Typography, Divider } from "@mui/material";
import { StepSessionRecorder } from "./utils";

// Styles
const useStyles = makeStyles((theme) => ({
  questionIndexContainer: {
    display: "flex",
    alignItems: "center"
  },
  carouselWrapper: {
    overflow: "hidden",
    flex: 1
  },
  carousel: {
    display: "flex",
    alignItems: "center"
  },
  questionIndex: {
    width: 48,
    height: 48,
    padding: 0,
    flexShrink: 0,
    marginLeft: "calc((25% - 48px) / 2)",
    marginRight: "calc((25% - 48px) / 2)",
    color: "white"
  },
  alignToStart: {
    marginRight: 0,
    marginLeft: theme.spacing(2)
  },
  selected: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main
  },
  number: {
    fontFamily: "Crimson Pro",
    fontSize: 23,
    height: 38,
    color: "inherit"
  },
  carouselArrow: {
    color: "white",
    "&.Mui-disabled": {
      color: "white",
      opacity: 0.2
    }
  },
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    backgroundColor: theme.palette.background.sidePanel,
    height: "100%",
    overflow: "auto",
    overflowX: "hidden",
    flexGrow: 1,
    borderLeft: "1px solid grey"
  },
  panelSubtitle: {
    whiteSpace: "pre-wrap",
    color: "#B7B7B7"
  },
  taskTitle: {
    color: "white",
    fontSize: "20px",
    marginBottom: 11,
    marginTop: theme.spacing(0.5)
  },
  panelHeader: {
    color: "white",
    width: "100%",
    textAlign: "left",
    paddingInline: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
    flexShrink: 0
  },
  divider: {
    backgroundColor: "#555555"
  },
  panelTitle: {
    color: "#bdbdbd",
    fontSize: "12px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "1px",
    lineHeight: "24px",
    marginTop: "8px",
    textTransform: "uppercase"
  },
  nextButton: {
    marginTop: "16px",
    fontSize: "13px",
    fontWeight: "bold",
    letterSpacing: "1px",
    color: "#ffffff",
    backgroundColor: "#168fee",
    "&:hover": {
      backgroundColor: "#1172be"
    },
    "&:disabled": {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.text.disabled
    }
  },
  questionTitle: {
    fontSize: "0.75rem",
    paddingLeft: "16px",
    paddingRight: "16px",
    margin: "20px 0",
    textTransform: "uppercase",
    color: "#BCD9F1"
  },
  highlightSwitch: {
    paddingInline: 24,
    color: "#FFFFFF",
    paddingBlock: 24
  },
  highlightSwtichSection: {
    overflow: "hidden",
    flexShrink: 0
  }
}));

export default function StandardSidebar({ rendition }) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const { submission_id } = useQuery();
  const panelRef = useRef();
  const [currentStep, setCurrentStep] = useState(null);

  const [step] = useStepStage();

  //Redux State
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector((state) => selectTask(state, submission.task_id));
  const questions = useSelector((state) =>
    selectQuestions(state, submission.task_id)
  );

  // Derived State
  const pageType = task.task_type === TASK.TYPE.STANDARD ? "task" : "gr";
  const isTask = !!submission_id;
  useEffect(() => {
    if (step) {
      setCurrentStep(step.toUpperCase());
    }
  }, [step]);

  return (
    <Paper className={classes.root} elevation={0} square ref={panelRef}>
      <Box
        className={clsx(classes.panelHeader)}
        data-testid="standard-task-sidebar">
        {/* This code logs user's change in step to Firestore */}
        {currentStep && task?.id && (
          <StepSessionRecorder
            submissionStatus={submission.status}
            step={step.toUpperCase()}
            task={task}
          />
        )}

        {isTask && (
          <Typography className={classes.panelTitle} color="textPrimary">
            <FormattedMessage
              id={
                task.task_type === TASK.TYPE.STANDARD
                  ? "appBar.task"
                  : "appBar.grTask"
              }
              defaultMessage="Assignment"
            />
          </Typography>
        )}
        <Typography className={clsx(classes.taskTitle)} color="textPrimary">
          {/* {isTask ? task.name : "Guided Reading"} */}
          {isTask
            ? task.name
            : intl.formatMessage({
                id: "gr.title",
                defaultMessage: "Guided Reading"
              })}
        </Typography>

        <Typography
          className={classes.panelSubtitle}
          color="textPrimary"
          variant="body2">
          {isTask
            ? task.task_description
            : intl.formatMessage({
                id: "gr.description",
                defaultMessage: "Follow the steps below"
              })}
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <StepsToggler firstQuestionId={questions[0]?.id} pageType={pageType} />
      <Divider className={classes.divider} />
      <Questions
        rendition={rendition}
        scrollToBottom={() => {
          panelRef.current.scrollTop = panelRef.current.scrollHeight;
        }}
      />
    </Paper>
  );
}

StandardSidebar.propTypes = {
  rendition: PropTypes.object
};

// Dependencies
import React, { useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
// Redux dependencies

//Components

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import { CardContent } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
    marginBlockEnd: theme.spacing(3),
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    textAlign: "start"
  }
}));

export default function InteractionCardContent({
  // Props that are passed implicitly from the parent Card component
  interaction,
  dispatch,
  collapseAfter,
  collapseStatus
}) {
  // Hooks
  const classes = useStyles();
  const contentRef = useRef(null);

  // Ephemeral state

  // Derived state
  const isCollapsed = collapseStatus === "COLLAPSED";

  useLayoutEffect(() => {
    // we calculate the number of lines in the content using the lineHeight and the scrollHeight of the content element. The scroll includes the overflowed content

    const contentElement = contentRef.current;
    const lineHeight = parseInt(getComputedStyle(contentElement).lineHeight);
    const contentHeight = contentElement.scrollHeight;
    const numLines = Math.floor(contentHeight / lineHeight);

    dispatch({ type: "setNumberOfLines", payload: numLines });
  }, [dispatch]);

  const cardLabel = `Content: ${interaction?.title ? interaction.title + ": " : ""} "${interaction.content || ""}"`;

  return (
    <CardContent
      data-testid="card-content-component"
      dir="auto"
      className={classes.content}
      role="textbox"
      aria-readonly="true"
      aria-label={cardLabel}
      style={{
        WebkitLineClamp: isCollapsed ? collapseAfter : "unset"
      }}
      ref={contentRef}>
      {interaction.content}
    </CardContent>
  );
}

InteractionCardContent.propTypes = {
  // There props are passed implicitly from the parent
  interaction: PropTypes.object,
  dispatch: PropTypes.func,
  collapseAfter: PropTypes.number,
  collapseStatus: PropTypes.string
};

import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import makeStyles from "@mui/styles/makeStyles";
import EmptyState from "../emptyState/EmptyState";
import { Box, ListItem, List } from "@mui/material";
import { useSelector } from "react-redux";
import { interactionsAPI } from "../../../api";
import clsx from "clsx";
import { CHATBOX_STATUS } from "../../../redux/chatSlice";
import AnswerCard from "./AnswerCard";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: 700,
    margin: "0 auto",
    marginTop: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  containerWithChat: {
    maxWidth: 700,
    marginTop: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  cardList: {
    width: "100%"
  }
}));

export function Cards({ cards = [], showInText, disable = false }) {
  const classes = useStyles();
  const visibleCards =
    cards && cards.filter && cards.filter((el) => !el.hidden);
  const hiddenCards = cards && cards.filter && cards.filter((el) => el.hidden);

  const chatStatus = useSelector((state) => state.chat.status);
  const chatIsVisible = chatStatus === CHATBOX_STATUS.OPEN;

  const handleKeyDown = (event, index) => {
    if (event.shiftKey) {
      if (event.key === "ArrowUp" && index > 0) {
        moveItem(index, index - 1);
      } else if (event.key === "ArrowDown" && index < visibleCards.length - 1) {
        moveItem(index, index + 1);
      }
    }
  };

  const moveItem = (sourceIndex, destinationIndex) => {
    const order = visibleCards.map((card) => card.id);
    const rollback = [...order];
    const sourceRef = order[sourceIndex];
    order.splice(sourceIndex, 1);
    order.splice(destinationIndex, 0, sourceRef);
    interactionsAPI.updateHighlightsOrder(order, rollback);
  };

  return cards.length ? (
    <Box
      className={clsx(
        classes.container,
        chatIsVisible && classes.containerWithChat
      )}>
      {visibleCards && (
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) return;
            moveItem(result.source.index, result.destination.index);
          }}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <List
                disablePadding
                dense
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={classes.cardList}>
                {[...visibleCards, ...hiddenCards].map((answer, index) => (
                  <Draggable
                    key={answer.id}
                    draggableId={answer.id}
                    index={index}
                    isDragDisabled={answer.hidden || disable}>
                    {(provided, snapshot) => (
                      <ListItem
                        disableGutters
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        role={"listitem"}
                        tabIndex="0"
                        onKeyDown={(event) => handleKeyDown(event, index)}>
                        <AnswerCard
                          answer={answer}
                          showInText={showInText}
                          disable={disable}
                        />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </Box>
  ) : (
    <EmptyState step="review" />
  );
}

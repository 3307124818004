// Dependancies
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useReducer
} from "react";
import PropTypes from "prop-types";
import { pdfjs } from "react-pdf";
import clsx from "clsx";
import { Document } from "react-pdf";
import {
  getPagesFromRange,
  getWindow,
  getClientRects,
  viewportToScaled
} from "../../../utils/pdf-utils";
import PdfCFI from "../../../utils/pdf-cfi";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { useIntl } from "react-intl";

// Redux dependancies
import { useDispatch, useSelector } from "react-redux";
import {
  closeAnnotatorBar,
  toggleNoQuestionsMessage
} from "../../../redux/highlightSlice";
import { setPdfTotalPages } from "../../../redux/pdfSlice";
import { selectCurrentText } from "../../../redux/textsSlice";
import { updateTextLocation } from "../../../redux/userSlice";
import { addSnackbar } from "../../../redux/snackbarSlice";
import { selectDarkMode } from "../../../redux/firestoreSelectors";

// Components
import PdfPageWithHighlights from "./PdfPageWithHighlights";
import { PdfHighlight } from "./PdfTypes";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import {
  scrollAnnotationIntoView,
  scrollPageAndThumbnailIntoView,
  getPDFTextSelection
} from "./utils";
import useResizeObserver from "../../../hooks/useResizeObserver";
import useConstrainedCaret from "../../../hooks/useConstrainedCaret";
import { debounce, isEmpty } from "lodash-es";
import { TEXT_TYPE } from "../../../consts";
import { logLocationChangeEvent } from "../utils";
import { useFirestoreConnect } from "../../../hooks/useFirestoreConnect";
import { selectBookmarkedPosition } from "../../../redux/firestoreSelectors";
import { keyframes } from "@emotion/react";
import { useLiveAnnouncer } from "../../../hooks/useLiveAnnouncer";

//pdf worker - version should match the one expected by react-pdf library
//pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js`;
const pdfjs_version = "4.8.69";
pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.mjs`;

//`//unpkg.com/pdfjs-dist@${pdfjs_version}/build/pdf.worker.min.mjs`;

const pdf_options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs_version}/cmaps/`,
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs_version}/standard_fonts`
};

// Styles
const useStyles = makeStyles(() => ({
  bookContainer: {
    overflowY: "auto",
    overflowX: "auto",
    width: "100%",
    height: "100%",
    display: "flex",
    paddingTop: "10px",
    flexDirection: "column",
    WebkitUserSelect: "text", // Enable text selection on iOS
    userSelect: "text",
    WebkitTouchCallout: "none" // Disable callout
  }
}));

const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const initialState = {};

function pagesReducer(state, action) {
  switch (action.type) {
    case "setPages": {
      const { numberOfPages } = action.payload;
      const updatedState = [...Array(numberOfPages)].reduce(
        (accumulator, curent, index) => {
          accumulator[index + 1] = false;
          return accumulator;
        },
        {}
      );

      return updatedState;
    }
    case "setIsRendered": {
      const { pageNumber, isRendered } = action.payload;
      const updatedState = { ...state };
      updatedState[pageNumber] = isRendered;
      return updatedState;
    }

    default:
      throw new Error();
  }
}

function PdfView({
  url,
  highlights = [],
  underlines = [],
  highlightClicked,
  handleTextSelected,
  backgroundColor,
  isVisible,
  onLoaded,
  scrollToPosition = {},
  isTouchActive
}) {
  // Hooks
  const { ref, width } = useResizeObserver();
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();
  const documentRef = useRef();
  const firstRenderRef = useRef(true);
  const firstScrollRef = useRef(true);
  const wrapperRef = useRef(null);
  const touchStartPositionRef = useRef(null);
  const { announce } = useLiveAnnouncer();

  useConstrainedCaret(wrapperRef);

  // Redux state
  const user = useSelector((state) => state.firebase.auth.uid);
  const text = useSelector((state) => selectCurrentText(state));
  const currentPage = useSelector((state) => state.pdf.currentPage);
  const isAnnotatorBarOpen = useSelector(
    (state) => state.highlighter.isAnnotatorBarOpen
  );
  const noQuestionsMessageOpen = useSelector(
    (state) => state.highlighter.noQuestionsMessageOpen
  );
  const zoom = useSelector((state) => state.pdf.zoom);
  const darkMode = useSelector((state) => selectDarkMode(state));

  // Now caretColor can safely use darkMode
  const caretColor = useMemo(() => {
    return darkMode ? "white" : "black";
  }, [darkMode]);

  useFirestoreConnect([
    {
      collection: "textLocations",
      doc: `${user}`,
      subcollections: [{ collection: "texts" }],
      storeAs: "textLocations"
    }
  ]);

  const bookmarkedPosition = useSelector((state) =>
    selectBookmarkedPosition(state, text.id)
  );

  // Ephemeral state

  // Derived State

  //whether highlight is interactive or not
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [renderedPages, dispatchRenderedPages] = useReducer(
    pagesReducer,
    initialState
  );

  const [touchSelectionActive, setTouchSelectionActive] = useState(false);

  // Derived state
  scrollToPosition = isEmpty(scrollToPosition)
    ? bookmarkedPosition
    : scrollToPosition;
  // Behavior;

  useEffect(() => {
    const originalGetContext = HTMLCanvasElement.prototype.getContext;
    HTMLCanvasElement.prototype.getContext = function (type, attributes) {
      if (type === "2d") {
        attributes = attributes || {};
        attributes.willReadFrequently = true;
      }
      return originalGetContext.call(this, type, attributes);
    };
    return () => {
      HTMLCanvasElement.prototype.getContext = originalGetContext;
    };
  }, []);

  useEffect(() => {
    function dispatchCloseAnnotatorBar() {
      if (isAnnotatorBarOpen) dispatch(closeAnnotatorBar());
      if (noQuestionsMessageOpen) dispatch(toggleNoQuestionsMessage());
      return;
    }

    document.addEventListener("scroll", dispatchCloseAnnotatorBar, true);
    return () => {
      document.removeEventListener("scroll", dispatchCloseAnnotatorBar, true);
    };
  }, [isAnnotatorBarOpen, dispatch]);

  const calculatePageRenderMode = useCallback(
    (pageNumber) => {
      if (Number(scrollToPosition.lastPage) === pageNumber) return "canvas";
      else {
        // We alow for max 5 canvas elements since they slow down rendering
        if (Math.abs(currentPage - pageNumber) <= 2) return "canvas";
        else {
          renderedPages[pageNumber] &&
            dispatchRenderedPages({
              type: "setIsRendered",
              payload: { pageNumber, isRendered: false }
            });
          return "none";
        }
      }
    },
    [scrollToPosition, currentPage, renderedPages]
  );

  const scrollToBookmark = useCallback(() => {
    scrollPageAndThumbnailIntoView(scrollToPosition.lastPage);
  }, [scrollToPosition?.lastPage]);

  // This is here to keep the reader on the last saved ...
  // ... page when cahnging the size or zoon on the reader,
  useEffect(() => {
    isVisible && scrollToBookmark();
  }, [width, zoom, isVisible]);
  useEffect(() => {
    if (!scrollToPosition.lastPage) return;
    else if (
      firstScrollRef.current &&
      renderedPages[scrollToPosition.lastPage]
    ) {
      const page = scrollToPosition.lastPage;
      const block = scrollToPosition.position;
      scrollPageAndThumbnailIntoView(page, { block: block });
      if (scrollToPosition?.id) scrollAnnotationIntoView(scrollToPosition?.id);
      firstScrollRef.current = false;
    }
  }, [
    renderedPages,
    scrollToPosition.position,
    scrollToPosition.lastPage,
    scrollToPosition?.id
  ]);

  function viewportPositionToScaled(pdfRects, pages) {
    //create scaled rectanges (with width and height of the fuill container)
    const pagesDict = pages.reduce((acc, entry) => {
      acc[entry.number] = entry.node.getBoundingClientRect();
      return acc;
    }, {});
    return pdfRects.map((pageRect) => {
      return {
        ...pageRect,
        pageRects: (pageRect.pageRects || []).map((rect) =>
          viewportToScaled(rect, pagesDict[pageRect.pageNumber])
        )
      };
    });
  }

  function handleSelectionEnd(event) {
    event?.stopPropagation();
    const isTouchEvent = event?.type?.startsWith("touch");

    if (isTouchEvent && !touchSelectionActive) return;

    onSelectionFinished();
    setTouchSelectionActive(false);
  }

  const handleTouchStart = useCallback(
    (event) => {
      if (!isTouchActive) return;
      event.preventDefault(); // Prevent default touch behavior

      const touch = event.touches[0];
      touchStartPositionRef.current = {
        x: touch.clientX,
        y: touch.clientY,
        time: Date.now()
      };

      setTouchSelectionActive(true);
    },
    [isTouchActive]
  );

  const handleTouchMove = useCallback(
    (event) => {
      if (!touchSelectionActive) return;
      event.preventDefault();

      const touch = event.touches[0];
      const selection = window.getSelection();

      // Cross-browser way to get element at point
      const element = document.elementFromPoint(touch.clientX, touch.clientY);
      if (!element) return;

      // Find text node at touch point
      const getTextNodeAtPoint = (element, x, y) => {
        const walker = document.createTreeWalker(
          element,
          NodeFilter.SHOW_TEXT,
          null,
          false
        );

        let node;
        while ((node = walker.nextNode())) {
          const range = document.createRange();
          range.selectNodeContents(node);
          const rects = range.getClientRects();

          for (const rect of rects) {
            if (
              y >= rect.top &&
              y <= rect.bottom &&
              x >= rect.left &&
              x <= rect.right
            ) {
              const offset = Math.round(
                ((x - rect.left) / rect.width) * node.length
              );
              return { node, offset };
            }
          }
        }
        return null;
      };

      const touchPoint = getTextNodeAtPoint(
        element,
        touch.clientX,
        touch.clientY
      );

      if (!touchPoint) return;

      // Handle the selection
      if (!selection.rangeCount) {
        // Create initial selection
        if (touchStartPositionRef.current) {
          const startElement = document.elementFromPoint(
            touchStartPositionRef.current.x,
            touchStartPositionRef.current.y
          );
          if (startElement) {
            const startPoint = getTextNodeAtPoint(
              startElement,
              touchStartPositionRef.current.x,
              touchStartPositionRef.current.y
            );
            if (startPoint) {
              const range = document.createRange();
              range.setStart(startPoint.node, startPoint.offset);
              range.collapse(true);
              selection.removeAllRanges();
              selection.addRange(range);
            }
          }
        }
      }

      // Extend the selection
      if (selection.rangeCount > 0 && touchPoint.node) {
        try {
          selection.extend(touchPoint.node, touchPoint.offset);
        } catch (e) {
          console.warn("Failed to extend selection:", e);
        }
      }
    },
    [touchSelectionActive]
  );
  const handleCaretScroll = useCallback((range) => {
    if (!range) return;
    const rect = range.getBoundingClientRect();
    const wrapper = documentRef.current;
    if (!wrapper) return;

    const containerRect = wrapper.getBoundingClientRect();
    const buffer = 50; // pixels of buffer

    if (rect.top < containerRect.top + buffer) {
      wrapper.scrollBy({
        top: rect.top - containerRect.top - buffer,
        behavior: "smooth"
      });
    } else if (rect.bottom > containerRect.bottom - buffer) {
      wrapper.scrollBy({
        top: rect.bottom - containerRect.bottom + buffer,
        behavior: "smooth"
      });
    }
  }, []);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter") {
        onSelectionFinished();
      } else if (
        e.shiftKey &&
        (e.key === "ArrowLeft" || e.key === "ArrowRight")
      ) {
        const container = documentRef.current;
        debouncedReadSelection(container);
      }

      // Handle caret movement with arrow keys
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        handleCaretScroll(range);
      }
    },
    [handleTextSelected, handleCaretScroll]
  );

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      wrapper.addEventListener("keydown", handleKeyDown);

      // Add styles for caret
      const style = document.createElement("style");
      style.textContent = `
        .textLayer {
          cursor: text !important;
          user-select: text !important;
          -webkit-user-select: text !important;
        }
        .textLayer:not(:has(::selection)) {
          caret-color: ${caretColor} !important;
          animation: ${blinkAnimation} 1s infinite;
        }
        .textLayer:has(::selection) {
          caret-color: ${caretColor} !important;
          animation: none;
        }
      `;
      document.head.appendChild(style);

      return () => {
        wrapper.removeEventListener("keydown", handleKeyDown);
        document.head.removeChild(style);
      };
    }
  }, [wrapperRef.current, handleKeyDown, caretColor]);

  function onSelectionFinished() {
    const container = documentRef.current;
    const selection = getWindow(container).getSelection();
    const corrected = getPDFTextSelection(selection);
    if (selection.isCollapsed) {
      isAnnotatorBarOpen && dispatch(closeAnnotatorBar());
      noQuestionsMessageOpen && dispatch(toggleNoQuestionsMessage());
      return;
    }

    const range =
      corrected?.correctedRange ||
      (selection.rangeCount > 0 ? selection.getRangeAt(0) : null);

    if (
      !range ||
      !container ||
      !container.contains(range.commonAncestorContainer)
    ) {
      return;
    }

    const pages = getPagesFromRange(range);

    if (!pages || pages.length === 0) return;
    if (pages.length > 1) {
      //Not upporting multi page highlights for now
      dispatch(
        addSnackbar({
          message: intl.formatMessage({
            id: "failedToHiglight.numPages",
            defaultMessage: "Highlights should be contained in a single page"
          })
        })
      );
      return;
    }
    const pdfRects = getClientRects(range, pages, container);
    if (pdfRects.length === 0) return;

    const cfi = new PdfCFI(
      range,
      pages[0].node.getElementsByClassName("textLayer")[0],
      pages[0].number,
      pages[pages.length - 1].node.getElementsByClassName("textLayer")[0],
      pages[pages.length - 1].number
    );

    //pdf ranges have <br/> that are not preserved or changed to newlines,
    //so this code will keep the text content and change br tags to spaces
    const rangeChildren = range.cloneContents().childNodes;
    let textContent = Array.from(rangeChildren)
      .reduce((result, node) => {
        let tempRes = result + node.textContent;
        if (node.tagName === "BR") {
          tempRes += " ";
        }
        return tempRes;
      }, "")
      .replace("  ", " ");

    const scaledPosition = viewportPositionToScaled(pdfRects, pages);
    //anomalous cfi selection may look like that: /4/4!,/218/1:22,/4/16/6/2/4/2/2/2/2/2/2/6/8/2/1:0)
    //preventing it from entering the system.
    if (cfi.toString().match(/\//g).length > 15) return;
    handleTextSelected({
      selection: {
        isPdf: true,
        cfi: cfi.toString(),
        content: textContent,
        pdfPosition: scaledPosition
      },
      pos: scaledPosition,
      clientRect: pdfRects
    });
  }

  const debouncedReadSelection = useMemo(
    () =>
      debounce((container) => {
        const selection = getWindow(container).getSelection();
        const corrected = getPDFTextSelection(selection);
        if (selection.isCollapsed) {
          return;
        }

        const range =
          corrected?.correctedRange ||
          (selection.rangeCount > 0 ? selection.getRangeAt(0) : null);

        if (
          !range ||
          !container ||
          !container.contains(range.commonAncestorContainer)
        ) {
          return;
        }

        const pages = getPagesFromRange(range);

        if (!pages || pages.length === 0) return;

        const rangeChildren = range.cloneContents().childNodes;
        let textContent = Array.from(rangeChildren)
          .reduce((result, node) => {
            let tempRes = result + node.textContent;
            if (node.tagName === "BR") {
              tempRes += " ";
            }
            return tempRes;
          }, "")
          .replace("  ", " ");

        announce(`Selected text:  ${textContent}`);
      }, 500),
    [announce]
  );

  useEffect(() => {
    return () => {
      debouncedReadSelection.cancel();
    };
  }, [debouncedReadSelection]);

  function displaySpinner() {
    return (
      <DotLottieReact
        src="/loading_book_lottie.json"
        mode="bounce"
        background="transparent"
        speed="1"
        style={{
          width: "300px",
          height: "300px",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)"
        }}
        loop
        autoplay
      />
    );
  }

  function handleLoadSuccess(pdf) {
    const { numPages } = pdf;

    dispatch(setPdfTotalPages(numPages));
    dispatchRenderedPages({
      type: "setPages",
      payload: { numberOfPages: numPages }
    });
    setNumberOfPages(numPages);

    onLoaded && onLoaded();
  }

  function handleScroll(e) {
    const target = e.currentTarget;
    updatePdfTextLocation(target);
    debouncedLogReadingAction(currentPage);
  }

  const updatePdfTextLocation = useCallback(() => {
    // Hack: skipping the first render otherwise it will set the location to page 1 on mount
    if (firstRenderRef.current) firstRenderRef.current = false;
    else {
      let text_id = text.id;
      dispatch(
        updateTextLocation({
          text_id,
          position: null,
          lastPage: currentPage,
          type: TEXT_TYPE.PDF
        })
      );
    }
  }, [dispatch, currentPage, text.id]);

  const logReadingAction = useCallback(
    (currentPage) => {
      if (text?.id) {
        // for PDF, the start and end are currently the same (current page)
        logLocationChangeEvent(
          text.id,
          "",
          text.course_id,
          user,
          currentPage,
          currentPage,
          TEXT_TYPE.PDF
        );
      }
    },
    [text.course_id, text.id, user]
  );

  const debouncedLogReadingAction = useMemo(
    () => debounce(logReadingAction, 5000),
    [logReadingAction]
  );

  const setPageRendered = useCallback((pageNumber) => {
    dispatchRenderedPages({
      type: "setIsRendered",
      payload: { pageNumber, isRendered: true }
    });
  }, []);

  // return focus after closing annotator bar
  useEffect(() => {
    if (!isAnnotatorBarOpen && wrapperRef.current) {
      wrapperRef.current.focus();
    }
  }, [isAnnotatorBarOpen]);

  return (
    <Box style={{ height: "100%" }} ref={ref}>
      {url && (
        <Box
          ref={wrapperRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleSelectionEnd}
          onMouseUp={handleSelectionEnd}
          tabIndex="0"
          sx={{
            outline: "none",
            height: "100%",
            WebkitUserSelect: "text",
            userSelect: "text",
            WebkitTouchCallout: "none",
            touchAction: "pan-y", // Allow vertical scrolling
            "& .textLayer": {
              caretColor: `${caretColor} !important`,
              WebkitUserSelect: "text",
              userSelect: "text",
              cursor: "text",
              MozUserSelect: "text"
            }
          }}>
          <Document
            tabIndex="0"
            className={clsx(classes.bookContainer)}
            style={{ backgroundColor }}
            options={pdf_options}
            loading={displaySpinner}
            file={url}
            onLoadError={(error) => {
              console.log("error", error);
            }}
            onLoadSuccess={handleLoadSuccess}
            onMouseUp={handleSelectionEnd}
            inputRef={documentRef}
            onScroll={(e) => handleScroll(e)}>
            {[...Array(numberOfPages)].map((k, i) => {
              const pageNumber = i + 1;
              //resetting styles because otherwise text layer and pdf canvas are misaligned
              return (
                <PdfPageWithHighlights
                  key={pageNumber}
                  highlights={highlights}
                  underlines={underlines}
                  scale={1}
                  pageNumber={pageNumber}
                  onHighlightClick={highlightClicked}
                  isVisible={isVisible}
                  width={width}
                  setPageRendered={setPageRendered}
                  renderMode={calculatePageRenderMode(pageNumber)}
                  setTextLayer={() => {}}
                />
              );
            })}
          </Document>
        </Box>
      )}
    </Box>
  );
}

PdfView.propTypes = {
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(ArrayBuffer)
  ]),
  highlightClicked: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  zoom: PropTypes.number,
  handleTextSelected: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  onLoaded: PropTypes.func,
  location: PropTypes.shape(PdfHighlight),
  highlights: PropTypes.arrayOf(PropTypes.shape(PdfHighlight)),
  underlines: PropTypes.arrayOf(PropTypes.shape(PdfHighlight)),
  locationChanged: PropTypes.func
};

// export default EpubView;
export default PdfView;

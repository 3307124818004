import React, { useEffect, useRef, useState } from "react";

// Redux Dependencies
import { useSelector, useDispatch } from "react-redux";
import { updateInteraction } from "../../redux/interactionsSlice";
import {
  selectSelectedHighlight,
  toggleAnnotatorBar
} from "../../redux/highlightSlice";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  colorBtn: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    cursor: "pointer",
    marginInline: theme.spacing(1),
    borderRadius: 8,
    WebkitTapHighlightColor: "transparent", // Remove default touch highlight
    "&:focus": {
      outline: "1px solid white",
      outlineOffset: "2px"
    },
    // Make touch target bigger while keeping visual size the same
    "@media (pointer: coarse)": {
      position: "relative",
      "&::after": {
        content: '""',
        position: "absolute",
        top: -8,
        left: -8,
        right: -8,
        bottom: -8
      }
    }
  },
  highlightBtn: {
    "& :hover": {
      color: "#BEF3BF"
    }
  },
  pressed: {
    transform: "scale(0.95)",
    transition: "transform 0.1s"
  }
}));

function HighlightColor({ color, focus, name }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [isPressed, setIsPressed] = useState(false);
  const touchStartRef = useRef(null);

  const selectedHighlight = useSelector((state) =>
    selectSelectedHighlight(state)
  );

  useEffect(() => {
    if (focus && ref.current) {
      ref.current.focus();
    }
  }, []);

  function updateColor(highlight, color) {
    dispatch(
      updateInteraction({
        interaction: highlight,
        update: { color: color }
      })
    );
    dispatch(toggleAnnotatorBar());
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      updateColor(selectedHighlight, color);
    }
  };

  const handleTouchStart = (event) => {
    event.stopPropagation(); // Prevent parent handlers from firing
    setIsPressed(true);

    // Store touch start position for move detection
    const touch = event.touches[0];
    touchStartRef.current = {
      x: touch.clientX,
      y: touch.clientY
    };
  };

  const handleTouchMove = (event) => {
    if (!touchStartRef.current) return;

    const touch = event.touches[0];
    const deltaX = Math.abs(touch.clientX - touchStartRef.current.x);
    const deltaY = Math.abs(touch.clientY - touchStartRef.current.y);

    // If finger moved more than 10px, cancel the press
    if (deltaX > 10 || deltaY > 10) {
      setIsPressed(false);
      touchStartRef.current = null;
    }
  };

  const handleTouchEnd = (event) => {
    event.stopPropagation();
    setIsPressed(false);

    // Only trigger if we haven't moved far from start position
    if (touchStartRef.current) {
      updateColor(selectedHighlight, color);
    }
    touchStartRef.current = null;
  };

  return (
    <Box
      ref={ref}
      aria-label={`Highlight with ${name.toLowerCase()}`}
      onClick={() => updateColor(selectedHighlight, color)}
      onKeyDown={handleKeyDown}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      className={`${classes.colorBtn} ${isPressed ? classes.pressed : ""}`}
      sx={{
        backgroundColor: color,
        transform: isPressed ? "scale(0.95)" : "scale(1)",
        transition: "transform 0.1s"
      }}
      tabIndex="0"
      role="button"
    />
  );
}

export default HighlightColor;

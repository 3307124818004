import React from "react";
import useLogUserSteps from "../../hooks/useLogUserSteps.js";
import { TASK } from "../../consts.js";

const activeStatuses = [
  TASK.SUBMISSION_STATUS.ACTIVE,
  TASK.SUBMISSION_STATUS.PENDING
];

export function StepSessionRecorder({ step, task, submissionStatus }) {
  const { course_id, id, text_id } = task;
  const LogUserSessionComponent = () => {
    useLogUserSteps(
      `${step}_SESSION`,
      { course_id, task_id: id, text_id },
      activeStatuses.includes(submissionStatus)
    );
    return null;
  };
  return <LogUserSessionComponent />;
}

// Dependencies
import React, { useRef, useState } from "react";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { useGetTheme } from "../../hooks";

// Redux Dependencies
import { useSelector } from "react-redux";
import { useHighlightColor } from "../../hooks/useHighlightColor";

import makeStyles from "@mui/styles/makeStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteFilledIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  ListItemIcon,
  Typography,
  Menu,
  MenuItem,
  Box
} from "@mui/material";
import { selectTextDirection } from "../../redux/firestoreSelectors";

// Styles
const useStyles = makeStyles((theme) => ({
  left: {
    textAlign: "left"
  },
  editBtn: {
    color: "white",
    opacity: 0,
    "&:hover": {
      backgroundColor: theme.palette.background.hover
    },
    display: "flex",
    maxHeight: "40px",
    maxWidth: "40px",
    padding: 0
  },
  selectedQuestionEditBtn: {
    color: "black"
  },
  right: {
    textAlign: "right"
  },
  leftDir: {
    textAlign: "left",
    direction: "ltr"
  },
  rightDir: {
    textAlign: "right",
    direction: "rtl"
  },
  listItem: {
    width: "24px",
    height: "24px",
    cursor: "pointer"
  },
  colorBtnRtl: {
    marginRight: "8px",
    marginLeft: "40px"
  },
  colorBtnLtr: {
    marginRight: "40px",
    marginLeft: "8px"
  },

  barIcon: {
    color: theme.palette.grey[900]
  },

  menu: {},
  menuLtr: {
    justifyContent: "left",
    direction: "ltr",
    textAlign: "left"
  },
  menuRtl: {
    justifyContent: "right",
    direction: "rtl",
    textAlign: "right"
  },

  menuItem: {
    fontSize: "14px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "0.1px",
    lineHeight: "22px"
  }
}));

const MuiMenu = React.forwardRef((props, ref) => {
  return <Menu ref={ref} {...props} data-no-dnd="true" />;
});

MuiMenu.displayName = "MuiMenu";

export function EditMenuItem(props) {
  const classes = useStyles();
  // Render
  return (
    <MenuItem
      tabIndex={0}
      className={clsx(classes.menuItem, classes.left)}
      onClick={props.click}>
      {props.icon && (
        <ListItemIcon className={classes.listItem}>{props.icon}</ListItemIcon>
      )}
      <Typography variant="inherit">{props.message}</Typography>
    </MenuItem>
  );
}

export function EditMenu(props) {
  // Hooks
  const textDirection = useSelector((state) => selectTextDirection(state));
  const classes = useStyles();
  const ref = useRef();

  // Ephemeral State
  const [anchorEl, setAnchorEl] = useState(false);

  // Render
  return (
    <React.Fragment>
      <IconButton
        aria-label={"Question actions"}
        ref={ref}
        className={clsx(classes.editBtn, {
          [classes.selectedQuestionEditBtn]: props.selected
        })}
        onClick={(e) => {
          setAnchorEl(ref.current);
          // setMousePos({  X: e.clientX - 2,Y: e.clientY - 4,} );
          e.stopPropagation();
        }}
        size="large">
        <MoreVertIcon data-no-dnd="true" />
      </IconButton>
      <MuiMenu
        direction={textDirection}
        onClick={(e) => {
          setAnchorEl(false);
          e.stopPropagation();
        }}
        //anchorReference="anchorPosition"
        // anchorPosition={mousePos}
        //getContentAnchorEl={()=>{

        // anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        //transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted
        classes={{
          list: textDirection === "rtl" ? classes.menuRtl : classes.menuLtr
        }}
        onClose={() => {
          // setMousePos({});
          setAnchorEl(null);
          props.handleClose && props.handleClose();
        }}
        // getContentAnchorEl={null}
        disableScrollLock={true}
        //anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        //transformOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        {props.children}
      </MuiMenu>
    </React.Fragment>
  );
}

export default function QuestionEditMenu(props) {
  const theme = useGetTheme();
  const highlightColor = useHighlightColor(props.questionId, true);

  const colors = [
    {
      color: "#BEF3BF",
      text: "colors.green",
      default: "Green",
      hlColor: "#BEF3BF"
    },
    {
      color: "#B9E7FF",
      text: "colors.cyan",
      default: "Cyan",
      hlColor: "#B9E7FF"
    },
    {
      color: "#ECC5FF",
      text: "colors.pink",
      default: "Pink",
      hlColor: "#ECC5FF"
    },
    {
      color: "#FF7F74",
      text: "colors.red",
      default: "Red",
      hlColor: "#FF7F74"
    },
    {
      color: "#FFE690",
      text: "colors.yellow",
      default: "Yellow",
      hlColor: "#FFE690"
    }
  ];

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <EditMenu
          selected={props.selected}
          open={Boolean(props.open)}
          handleClose={props.handleClose}
          anchorPosition={{
            left: props.mouseX ? props.mouseX : 0,
            top: props.mouseY ? props.mouseY : 0
          }}>
          {props.editable && (
            <EditMenuItem
              icon={<EditIcon />}
              message={
                <FormattedMessage id="actions.edit" defaultMessage="Edit" />
              }
              click={props.onEdit}
            />
          )}
          {props.editable && (
            <EditMenuItem
              icon={<DeleteFilledIcon />}
              message={
                <FormattedMessage id="actions.delete" defaultMessage="Delete" />
              }
              click={props.onDelete}
            />
          )}
          {colors.map((color) => {
            return (
              <MenuItem
                key={color.color}
                onClick={() => props.onColor(color.hlColor)}>
                <ListItemIcon>
                  <Box
                    sx={{
                      backgroundColor: color.color,
                      borderRadius: "12px",
                      width: "20px",
                      height: "20px",
                      border:
                        highlightColor === color.hlColor
                          ? "2px solid black"
                          : ""
                    }}
                    role="checkbox" //FIXME: This should really be a radiogrop
                  />
                </ListItemIcon>
                <Typography variant="inherit" id={`${color.text} label`} noWrap>
                  <FormattedMessage
                    id={color.text}
                    defaultMessage={color.default}
                  />
                </Typography>
              </MenuItem>
            );
          })}
        </EditMenu>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

import React from "react";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Avatar } from "@mui/material";
import {
  generateBackgroundColor,
  generateAccessibleTextColor,
  getInitials
} from "../../../utils/avatar-utils.js";

// Styles
const useStyles = makeStyles((theme) => ({
  button: {
    color: "currentcolor"
  }
}));

export default function CustomAvatar({
  className,
  id = null,
  src = null,
  name = null,
  minimized = false,
  children = null
}) {
  const classes = useStyles();

  const backgroundColor = generateBackgroundColor(id);
  const textColor = generateAccessibleTextColor(backgroundColor);

  return (
    <Avatar
      src={!children && src ? src : ""}
      className={clsx(classes.avatar, className, "sentry-mask")}
      alt={name}
      sx={{
        backgroundColor: backgroundColor,
        color: textColor,
        width: 32,
        height: 32
      }}>
      {children || getInitials(name, minimized)}
    </Avatar>
  );
}

import React, { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import { Box, Typography, FormHelperText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NewTextEditorToolbar from "./NewTextEditorToolbar";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    height: (props) => (props.fullHeight ? "100%" : "inherit"),
    display: "flex",
    flexDirection: "column"
  },
  editorContainer: {
    border: `1px solid ${theme.palette.textEditor.border}`,
    borderRadius: "4px",
    height: (props) => (props.fullHeight ? "100%" : "inherit"),
    padding: theme.spacing(3),
    "&.error": {
      borderColor: theme.palette.error.main
    },
    display: "flex",
    flexDirection: "column"
  },
  editor: (props) => ({
    minHeight: "200px",
    maxHeight: props.fullHeight ? "100%" : "200px",
    height: props.fullHeight
      ? `calc(100% - ${props.isFixed ? "89px" : "28px"})`
      : "inherit",
    position: "relative",
    cursor: "text",
    "& .ProseMirror": {
      outline: "none",
      "&:focus": {
        outline: "none"
      },
      "& > * + *": {
        marginTop: "0.75em"
      },
      "& p, & h1, & h2, & h3, & h4, & h5, & h6": {
        margin: 0
      },
      "& ul, & ol": {
        padding: "0 1rem",
        margin: 0
      }
    },
    overflow: "auto",
    flex: "1 1 auto",
    overflowX: "hidden",
    fontFamily: "Chivo",
    ...props.editorStyles
  }),
  placeholder: {
    position: "absolute",
    top: 0,
    left: 0,
    pointerEvents: "none",
    color: theme.palette.textEditor.placeholder,
    padding: "inherit",
    fontSize: "inherit"
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary
  }
}));

const NewTextEditor = React.forwardRef(
  (
    {
      initialContent = "",
      toolbar = "floating",
      onContentChange,
      onEnterPress,
      disabled = false,
      showWordCount = false,
      placeholder = "Enter some text...",
      error = "",
      actions,
      toolbarOptions,
      fullHeight = false,
      editorStyles = {},
      preventScroll = false
    },
    ref
  ) => {
    const classes = useStyles({
      fullHeight,
      isFixed: toolbar === "fixed",
      editorStyles
    });
    const editorRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const prevDisabledRef = useRef(disabled);
    const prevInitialContentRef = useRef("");

    const editor = useEditor({
      extensions: [
        StarterKit,
        Underline,
        Link.configure({
          openOnClick: false
        })
      ],
      content: initialContent,
      autofocus: true,
      editable: !disabled,
      onUpdate: ({ editor }) => {
        const content = editor.getHTML();
        const plainText = editor.getText();
        const wordCount =
          plainText.trim().length > 0
            ? plainText.trim().split(/\s+/).length
            : 0;
        onContentChange({ plainText, richText: content, wordCount });
      },
      handleKeyDown: (view, event) => {
        if (event.key === "Enter" && !event.shiftKey) {
          event.preventDefault();
          onEnterPress();
          return true;
        }
        return false;
      },
      onFocus: () => setIsFocused(true),
      onBlur: () => setIsFocused(false),
      parseOptions: {
        preserveWhitespace: true
      }
    });

    useEffect(() => {
      // editor editable should track disabled prop
      if (editor && editor?.options && editor.options?.editable != !disabled) {
        editor.options.editable = !disabled;
      }
    }, [disabled, editor]);

    // Expose editor instance through ref
    React.useImperativeHandle(ref, () => ({
      editor,
      clearContent: () => {
        editor?.commands.clearContent();
      },
      focus: () => {
        editor?.commands.focus();
      }
    }));

    const wordCount = useCallback(() => {
      if (editor) {
        return editor.getText().trim().split(/\s+/).length;
      }
      return 0;
    }, [editor]);

    const handleContainerClick = useCallback(
      (event) => {
        if (editor && !disabled) {
          const editorElement = editorRef.current.querySelector(".ProseMirror");
          const toolbarElement = editorRef.current.querySelectorAll(
            ".tippy-box, .editor-toolbar"
          );
          if (
            editorElement &&
            !editorElement.contains(event.target) &&
            (!toolbarElement ||
              !Array.from(toolbarElement).some((e) => e.contains(event.target)))
          ) {
            editor.chain().focus("end").run();
          }
        }
      },
      [editor, disabled]
    );

    useEffect(() => {
      const container = editorRef.current;
      if (container) {
        container.addEventListener("mousedown", handleContainerClick);
        return () => {
          container.removeEventListener("mousedown", handleContainerClick);
        };
      }
    }, [handleContainerClick]);

    useEffect(() => {
      if (prevDisabledRef.current !== disabled) {
        !disabled && editor.chain().focus("end").run();
        prevDisabledRef.current = disabled;
      }
    }, [disabled]);

    useEffect(() => {
      if (
        initialContent &&
        editorRef.current &&
        !prevInitialContentRef.current &&
        !preventScroll
      ) {
        prevInitialContentRef.current = initialContent;
        setTimeout(() => {
          editor.chain().focus("end").run();
        }, 0);
      }
    }, [initialContent]);

    return (
      <Box className={classes.container}>
        <Box
          className={clsx(classes.editorContainer, "editorContainer", {
            error: !isFocused && error
          })}
          ref={editorRef}>
          {editor && toolbar !== "none" && (
            <NewTextEditorToolbar
              editor={editor}
              toolbarOptions={toolbarOptions}
              toolbar={toolbar}
              actions={actions}
            />
          )}
          <Box className={clsx(classes.editor, "editor")}>
            <EditorContent editor={editor} />
            {editor && editor.isEmpty && (
              <div className={classes.placeholder}>{placeholder}</div>
            )}
          </Box>
          {showWordCount && (
            <Box className={classes.footer}>
              <Typography variant="caption">
                Word count: {wordCount()}
              </Typography>
            </Box>
          )}
        </Box>
        {error && !isFocused && <FormHelperText error>{error}</FormHelperText>}
      </Box>
    );
  }
);

NewTextEditor.displayName = "NewTextEditor";

export default NewTextEditor;

// Dependancies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import ClipLoader from "react-spinners/ClipLoader";

// Components
import Thumbnail from "./Thumbnail";
import TooltipWithIntl from "../../../SharedComponents/tooltip/TooltipWithIntl";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { selectIsThumbnails } from "../../../../redux/firestoreSelectors";
import { pdfThumbnailsIconClicked } from "../../../../redux/layoutSlice";

//Material UI
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import useRetrieveTextThumbnails from "../../../../hooks/useRetrieveTextThumbnails";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.rightSidePanel,
    height: "calc(100vh - 120px)",
    width: "100%",
    maxWidth: "250px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  thumbnailsView: {
    marginTop: theme.spacing(7)
  },
  thumbnailContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },

  loadingStatus: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
    width: "100%",
    marginTop: theme.spacing(5)
  },
  pageNumber: {
    marginBlock: theme.spacing(1)
  },
  collapsed: {
    display: "none"
  },
  thumbnailsHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.background.rightSidePanel,
    position: "fixed",
    width: "250px",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    marginBottom: theme.spacing(5),
    paddingInline: theme.spacing(1.5),
    height: "40px"
  },
  thubmnailsHeaderText: {
    color: theme.palette.text.secondary,
    fontSize: "14px"
  }
}));

function PDFThumbBar({ setPagesState }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedTextId = useSelector((state) => state.texts.selectedTextId);
  const currentPage = useSelector((state) => state.pdf.currentPage);
  const {
    files: thumbnailsFiles,
    loading: thumbnailsLoading,
    error: thumbnailsError
  } = useRetrieveTextThumbnails(selectedTextId);

  // Redux State
  const isThumbnails = useSelector((state) => selectIsThumbnails(state));

  // Variables
  const [thumbnails, setThumbnails] = useState([]);

  useEffect(() => {
    if (!thumbnailsLoading && !thumbnailsError) {
      setThumbnails(thumbnailsFiles);
    }
  }, [thumbnailsLoading]);

  if (!thumbnails.length) {
    return (
      <>
        <Box className={classes.loadingStatus}>
          <Typography component="span" variant="subtitle2">
            Loading PDF thumbnails...
          </Typography>
          <ClipLoader color="#5ec891" loading="true" size={50} />
        </Box>
      </>
    );
  } else {
    return (
      <Box
        className={clsx(isThumbnails ? classes.container : classes.collapsed)}>
        <Box className={classes.thumbnailsHeader}>
          <Typography
            variant="paragraph"
            id="toggleThumbnails"
            className={classes.thubmnailsHeaderText}>
            <FormattedMessage
              id="toggle.thumbnails"
              defaultMessage="Thumbnails"
            />
          </Typography>
          <TooltipWithIntl
            defaultMessage={"close thumbnails"}
            intlStringId={"close thumbnails"}
            placement="bottom">
            <IconButton
              aria-label="close thumbnails"
              size="medium"
              label="Thumbnails"
              className={classes.closeThumbnailsButton}
              onClick={() => {
                dispatch(pdfThumbnailsIconClicked());
              }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </TooltipWithIntl>
        </Box>
        <Box className={classes.thumbnailsView}>
          {thumbnails.map((thumbnail, index) => {
            const pageNumber = index + 1;
            return (
              <Box key={pageNumber} className={classes.thumbnailContainer}>
                <Thumbnail
                  key={pageNumber}
                  pageNum={pageNumber}
                  thumbnailSrc={thumbnail}
                  currentPage={currentPage}
                />
                <Typography
                  className={classes.pageNumber}
                  variant="caption"
                  component={"span"}>
                  {pageNumber}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }
}

export default PDFThumbBar;

PDFThumbBar.propTypes = {
  thumbnails: PropTypes.array,
  setPagesState: PropTypes.func
};

// Dependencies
import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Redux Dependencies

// Components
import useKeypress from "../../hooks/useKeyPress";
import makeStyles from "@mui/styles/makeStyles";
import { Button, TextareaAutosize } from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  text: {
    width: "100%",
    paddingBlock: theme.spacing(0),
    paddingInline: 0,
    fontSize: 20,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    lineHeight: "1.5"
  },
  inlineText: {
    display: "block",
    wordBreak: "break-word",
    cursor: "pointer",
    outlineWidth: 0,
    justifyContent: "flex-start",
    textAlign: "left"
  },

  inlineTextInput: {
    display: "block",
    background: "none",
    outline: "none",
    border: "none"
  },

  hidden: {
    display: "none"
  }
}));

const InlineEditTextarea = React.forwardRef((props, ref) => {
  const { text = "", onChange, setIsDraggable, maxRows } = props;

  // Hooks
  const classes = useStyles();
  const textRef = useRef(null);
  const inputRef = useRef(null);
  const enter = useKeypress("Enter");

  // Ephemeral State
  const [isInputActive, setIsInputActive] = useState(false);
  const [inputValue, setInputValue] = useState(text);

  // Redux State

  // Behavior
  useImperativeHandle(ref, () => ({
    // https://reactjs.org/docs/hooks-reference.html#useimperativehandle
    // using useImperativeHandle hook to expose a function to the parent component using the ref.current.focus() syntax
    focus: () => {
      if (!isInputActive) {
        setIsInputActive(true);
        setIsDraggable && setIsDraggable(false);
      } else {
        saveAndExit(inputValue);
      }
    }
  }));

  useEffect(() => {
    // We are using an effect to set focuse because we need to wait for the element to be visible, otherwise the element is not found.
    if (isInputActive) inputRef.current.focus();
    else inputRef?.current?.blur();
  }, [isInputActive]);

  function saveAndExit(valueToSave) {
    onChange(valueToSave);
    setIsInputActive(false);
    inputRef.current.blur();
    setIsDraggable && setIsDraggable(true);
  }

  // Render
  return text || isInputActive ? (
    <>
      <Button
        ref={textRef}
        onClick={() => {
          setIsInputActive(true);
          setIsDraggable && setIsDraggable(false);
        }}
        onKeyPress={() => {
          if (enter) {
            setIsInputActive(true);
            setIsDraggable && setIsDraggable(false);
          }
        }}
        className={clsx(
          classes.text,
          classes.inlineText,
          isInputActive && classes.hidden
        )}
        label={text}
        aria-label={`Edit title ${text ? `(currently "${text}")` : ""}`}>
        {text}
      </Button>
      <TextareaAutosize
        value={inputValue}
        ref={inputRef}
        maxRows={maxRows}
        className={clsx(
          classes.text,
          classes.inlineTextInput,
          !isInputActive && classes.hidden
        )}
        style={{ resize: "none" }}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        onBlur={() => {
          // if Tab is pressed, save text
          saveAndExit(inputValue);
        }}
        onKeyPress={(e) => {
          // if Enter is pressed, save text
          if (enter) {
            saveAndExit(inputValue);
          }
        }}
        onKeyDown={(e) => {
          // if Escape is pressed, revert the text and exit
          if (e.key === "Escape") {
            saveAndExit(text);
          }
        }}
        aria-label="Editable text area"
      />
    </>
  ) : (
    <> </>
  );
});

InlineEditTextarea.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func,
  setIsDraggable: PropTypes.func,
  maxRows: PropTypes.number,
  disablePadding: PropTypes.bool
};
InlineEditTextarea.displayName = "InlineEditTextarea";

export default InlineEditTextarea;

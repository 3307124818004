import React, { Component, useEffect } from "react";
// Dependancies
import PropTypes from "prop-types";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import Epub from "epubjs/lib/index";
import EpubCifi from "epubjs/lib/epubcfi";
import { getEpubContentInRange, logLocationChangeEvent } from "../utils";
import { captureException } from "../../../utils/errorHandlers";
import { getScrolledRectPosition } from "../../annotations/utils";
import { debounce } from "lodash-es";
import { TEXT_TYPE } from "../../../consts";
import { selectCurrentText } from "../../../redux/textsSlice";
import { updateTextPosition } from "../../../redux/readerSlice";
import TouchHighlightHandler from "./TouchHighlightHandler";

//Redux Dependancies
import { connect } from "react-redux";
import {
  openAnnotatorBar,
  closeAnnotatorBar,
  setSelectedHighlight,
  toggleNoQuestionsMessage
} from "../../../redux/highlightSlice";
import { updateTextLocation } from "../../../redux/userSlice";
import RenditionContext from "../../../RenditionContext";
// Material UI
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import { selectDarkMode } from "../../../redux/firestoreSelectors";

global.ePub = Epub; // Fix for v3 branch of epub.js -> needs ePub to by a global var

const ViewDiv = styled(Box)({
  height: "100%",
  width: "100%",
  "& svg": {
    zIndex: "-1"
  },
  "& .epub-container": {
    display: "flex",
    justifyContent: "center",
    overflowY: "scroll",
    "& .epub-view": {
      overflow: "visible !important"
    }
  }
});

const mapStateToProps = (state) => ({
  darkMode: selectDarkMode(state),
  user: state.firebase.auth.uid,
  //FIXME: This will add a label as long as theres a title in redux. It won't work for the reader that opens up in create new task
  textTitle: state.texts?.selectedText?.name,
  courseName: state.texts?.selectedText?.course_name,
  course_id: selectCurrentText(state).course_id,
  textLanguage: state.texts?.selectedText?.text_language,
  isAnnotatorBarOpen: state.highlighter.isAnnotatorBarOpen,
  noQuestionsMessageOpen: state.highlighter.noQuestionsMessageOpen
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeAnnotatorBar: () => dispatch(closeAnnotatorBar()),
    openAnnotatorBar: () => dispatch(openAnnotatorBar()),
    setSelectedHighlight: (payload) => dispatch(setSelectedHighlight(payload)),
    updateTextLocation: (payload) => dispatch(updateTextLocation(payload)),
    updateTextPosition: (payload) => dispatch(updateTextPosition(payload)),
    toggleNoQuestionsMessage: () => dispatch(toggleNoQuestionsMessage())
  };
};

const UseEffectForAnnotatorBar = ({ isAnnotatorBarOpen, viewerRef }) => {
  useEffect(() => {
    if (!isAnnotatorBarOpen) {
      viewerRef.current.focus();
    }
  }, [isAnnotatorBarOpen]);

  return null;
};

class EpubView extends Component {
  resizeObserver = null;
  static contextType = RenditionContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      mouseEvent: {},
      toc: [],
      uxDelayLoad: true,
      monitorLocationChange: false,
      mouseClicked: false,
      selectedText: null,
      selectedCfi: null
    };
    this.container = props.container || null;
    this.viewerRef = React.createRef();
    this.location = props.location;
    this.defaultLocation = props.defaultLocation;
    this.fullsize = props.fullSize;
    this.book = this.rendition = this.prevPage = this.nextPage = null;
    this.darkMode = props.darkMode;
    this.textTitle = props.textTitle;
    this.courseName = props.courseName;
    this.fontSize = props.fontSize;
    this.mounted = true;
    this.updateRenditionContext = props.updateRenditionContext;
  }

  componentDidMount() {
    this._ismounted = true;
    this.initBook(true);
    document.addEventListener("keyup", this.handleKeyPress, false);
    this.to = this.setState({ uxDelayLoad: false });
    document.addEventListener("scroll", () => this.handleActions(), true);
  }

  componentWillUnmount() {
    this.book.destroy();
    this.book = this.rendition = this.prevPage = this.nextPage = null;
    this.updateRenditionContext && this.context.setRendition(null);

    document.removeEventListener("keyup", this.handleKeyPress, false);
    clearTimeout(this.to);
    document.removeEventListener("scroll", () => this.handleActions(), true);
  }

  shouldComponentUpdate(nextProps) {
    return (
      !this.state.isLoaded ||
      nextProps.url !== this.props.url ||
      nextProps.location !== this.props.location ||
      nextProps.darkMode !== this.props.darkMode ||
      nextProps.isAnnotatorBarOpen !== this.props.isAnnotatorBarOpen
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.darkMode !== this.props.darkMode ||
      prevProps.url !== this.props.url
    ) {
      this.initBook();
    }
  }

  initBook(first) {
    const { url, tocChanged, epubInitOptions } = this.props;
    if (this.book) {
      this.book.destroy();
      this.updateRenditionContext && this.context.setRendition(null);
    }
    this.book = new Epub(url, epubInitOptions);
    this.book.loaded.navigation.then(({ toc }) => {
      this.setState({ isLoaded: true, toc: toc }, () => {
        tocChanged && tocChanged(toc);
        this.initReader();
      });
    });
  }

  initReader() {
    const { toc } = this.state;
    const {
      location,
      defaultLocation,
      epubOptions,
      getRendition,
      handleKeyPress,
      textDirection,
      darkMode,
      textTitle,
      courseName,
      bodyClassName
    } = this.props;

    let darkMode_postfix = darkMode ? "_dark" : "";
    let rtl_postfix = "_" + textDirection;
    let style_file =
      "/resources/epub_1" + darkMode_postfix + rtl_postfix + ".css";
    const node = this.viewerRef.current;

    if (node) {
      let bookProps = {
        contained: true,
        width: "100%",
        height: "100%",
        stylesheet: style_file,
        view: "iframe",
        defaultDirection: textDirection,
        ignoreClass: "pangea-elem",
        fullsize: false,
        allowScriptedContent: true,
        ...epubOptions
      };

      this.rendition = this.book.renderTo(node, bookProps);

      if (bodyClassName) {
        this.rendition.hooks.content.register(function (contents, view) {
          const element = contents.document.querySelector("body");
          element.classList.add(bodyClassName);
        });
      }

      this.rendition.themes.default(this.fontSize);
      this.rendition.on("locationChanged", this.onLocationChange);
      this.rendition.on("keyup", handleKeyPress || this.handleKeyPress);
      this.rendition.on("mouseup", this.handleMouseUp);
      this.rendition.on("mousedown", this.handleMouseDown);
      this.rendition.on("markClicked", (cfi, data, content) =>
        this.handleMarkClicked(cfi, data, content)
      );

      getRendition && getRendition(this.rendition);

      this.rendition.on("rendered", (section, view) => {
        this.updateRenditionContext &&
          this.context.setRendition(this.rendition);

        if (view.iframe) {
          if (courseName && textTitle) {
            view.iframe.setAttribute(
              "title",
              `Alethea Reader, currently viewing ${textTitle} from ${courseName} course`
            );
          } else {
            view.iframe.setAttribute("title", `Alethea Reader`);
          }
        }
        this.props.onRenditionCreated &&
          this.props.onRenditionCreated(this.rendition);
      });

      if (this.rendition) {
        setTimeout(async () => {
          if (!this.rendition) return;
          let loc =
            typeof location === "string" || typeof location === "number"
              ? location
              : defaultLocation;
          try {
            await this.rendition.display(loc);
          } catch (err) {
            captureException(
              err,
              `Failed render book, invalid location ${loc}`
            );
            this.rendition.display(defaultLocation);
          }
        }, 400);
        setTimeout(() => {
          this.setState({ monitorLocationChange: true });
        }, 1000);
      }
    }
  }

  handleActions = () => {
    this.props.isAnnotatorBarOpen && this.props.closeAnnotatorBar();
    this.props.noQuestionsMessageOpen && this.props.toggleNoQuestionsMessage();
  };

  handleMouseDown = () => {
    if (!this.rendition) return;
    this.handleActions();
  };

  handleMarkClicked = (cfi, data, content) => {
    const contents = this.rendition.getContents();
    let cfiPosition = this.rendition.getContents()[0].locationOf(cfi);
    if (cfiPosition.x !== 0 || cfiPosition.y !== 0 || cfiPosition.left) {
      const containerRect = this.container.current.getBoundingClientRect();
      const iframeRect =
        contents[0].document.defaultView.frameElement.getBoundingClientRect();
      const range = this.rendition.getRange(cfi);
      const elementRect = range.getBoundingClientRect();

      let scrolledRect = getScrolledRectPosition(
        containerRect,
        iframeRect,
        elementRect
      );

      if (!this.props.readOnly || this.props.isAnnotatorBarOpen) {
        const highlight = this.props.highlights.find((highlight) => {
          if (
            highlight.cfi === cfi &&
            highlight.interaction_type !== "CONTAINER"
          )
            return highlight;
        });
        if (!highlight) return;
        this.props.setSelectedHighlight({
          clientRectangle: scrolledRect,
          selectedHighlight: highlight.id,
          isClickOnMark: true,
          minimal: highlight.minimal
        });
        this.props.openAnnotatorBar();
      }
    }
  };

  handleMouseUp = () => {
    this.updateSelection("mouse");
  };

  handleSelectionChange = () => {
    this.updateSelection("keyboard");
  };

  updateSelection = (inputMethod) => {
    const content = this.rendition?.getContents()[0];
    const selection = content?.window?.getSelection();

    if (selection && selection.rangeCount > 0 && !selection.isCollapsed) {
      let range = selection.getRangeAt(0);
      let cfirange = new EpubCifi(range, content.cfiBase).toString();

      if (inputMethod === "mouse") {
        this.showAnnotator(cfirange);
      } else if (inputMethod === "keyboard") {
        const text = selection.toString();
        this.setState({ selectedText: text, selectedCfi: cfirange });
      }
    } else {
      if (inputMethod === "mouse") {
        this.handleActions();
      } else if (inputMethod === "keyboard") {
        this.setState({ selectedText: null, selectedCfi: null });
      }
    }
  };

  showAnnotator = (cfi) => {
    const { handleTextSelected } = this.props;
    if (!handleTextSelected) return;
    const contents = this.rendition.getContents();
    const cfiPosition = this.rendition.getContents()[0].locationOf(cfi);
    contents.forEach((content) => {
      const range = content.range(cfi);
      if (range) {
        const text = range.toString();

        if (cfiPosition.x !== 0 || cfiPosition.y !== 0 || cfiPosition.left) {
          const containerRect = this.container.current.getBoundingClientRect();
          const iframeRect =
            contents[0].document.defaultView.frameElement.getBoundingClientRect();
          const range = this.rendition.getRange(cfi);
          const elementRect = range.getBoundingClientRect();
          const scrolledRect = getScrolledRectPosition(
            containerRect,
            iframeRect,
            elementRect
          );

          handleTextSelected({
            selection: { content: text, cfi },
            clientRect: scrolledRect,
            pos: cfiPosition
          });
        }
      }
    });
  };

  handleKeyPress = (event) => {
    this.updateSelection("keyboard");

    if (
      event.key === "Enter" &&
      this.state.selectedText &&
      this.state.selectedCfi
    ) {
      this.showAnnotator(this.state.selectedCfi);
    }
  };
  onLocationChange = (loc) => {
    if (!this.state.monitorLocationChange) return;
    const { location, text_id } = this.props;
    const newLocation = loc && loc.start;
    if (location !== newLocation) {
      this.location = newLocation;
      const content = getEpubContentInRange(this.rendition, loc.start, loc.end);
      this.props.updateTextPosition({
        startPosition: loc.start,
        endPosition: loc.end,
        content
      });

      // update location in firebase
      this.props.updateTextLocation({
        text_id,
        position: newLocation,
        type: TEXT_TYPE.EPUB,
        lastPage: null
      });
      this.logReadEvent(content, text_id, newLocation, loc.end);
    }
  };

  logReadEvent = debounce((content, text_id, start, end) => {
    if (text_id) {
      logLocationChangeEvent(
        text_id,
        content,
        this.props.course_id,
        this.props.user,
        start,
        end,
        TEXT_TYPE.EPUB
      );
    }
  }, 5000);

  isTouchDevice() {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
  }

  render() {
    const { isLoaded, uxDelayLoad } = this.state;
    return (
      <>
        <UseEffectForAnnotatorBar
          isAnnotatorBarOpen={this.props.isAnnotatorBarOpen}
          viewerRef={this.viewerRef}
        />
        <motion.div
          animate={{
            opacity: isLoaded && !uxDelayLoad ? 1 : 0,
            height: "100%"
          }}
          onMouseDown={this.handleMouseDown}>
          <ViewDiv data-testid="the-text" ref={this.viewerRef} tabIndex="0" />
          {this.isTouchDevice() && this.rendition && (
            <TouchHighlightHandler
              rendition={this.rendition}
              container={this.container}
              onHighlightCreated={this.showAnnotator}
            />
          )}
        </motion.div>
        {!isLoaded || uxDelayLoad ? (
          <DotLottieReact
            src="/loading_book_lottie.json"
            mode="bounce"
            background="transparent"
            speed="1"
            style={{
              width: "300px",
              height: "300px",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)"
            }}
            loop
            autoplay
          />
        ) : null}
      </>
    );
  }
}

EpubView.defaultProps = {
  loadingView: null,
  tocChanged: null,
  classes: {},
  epubOptions: {},
  epubInitOptions: {},
  updateRenditionContext: true
};

EpubView.propTypes = {
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(ArrayBuffer)
  ]),
  loadingView: PropTypes.element,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tocChanged: PropTypes.func,
  classes: PropTypes.object,
  epubInitOptions: PropTypes.object,
  epubOptions: PropTypes.object,
  getRendition: PropTypes.func,
  handleKeyPress: PropTypes.func,
  handleTextSelected: PropTypes.func,
  updateRenditionContext: PropTypes.bool,
  updateTextLocation: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(EpubView);

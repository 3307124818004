// Dependencies
import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { motion, useAnimation } from "framer-motion";
import { useMatch } from "react-router";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { selectTextDirection } from "../../redux/firestoreSelectors";
import { grAPI, interactionsAPI } from "../../api";

// Components
import QuestionEditMenu from "./QuestionEditMenu";
import EditingButton from "../EditingButton";
import { makeStyles } from "@mui/styles";
import { IconButton, Typography } from "@mui/material";
import { TriangleRight, TriangleLeft } from "@styled-icons/octicons";
import { useDrop } from "react-dnd";
import { setInteractionColor } from "../../redux/interactionsSlice";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingBlock: theme.spacing(1.5),
    display: "flex",
    flexWrap: "wrap",
    cursor: "pointer",
    "&:hover button, &:focus-within button": {
      opacity: 1,
      transition: ".5s ease-out",
      right: "0px"
    },
    "&:focus": {
      outlineColor: "white"
    },
    alignItems: "center",
    paddingInlineEnd: theme.spacing(2),
    border: "0px",
    textAlign: "left"
  },
  selected: {
    "&:hover": {
      background: "rgba(0, 0, 0, 0.1)"
    }
  },
  title: {
    flex: "75%",
    cursor: "pointer"
  },
  subTitle: {
    color: "#4F4F4F",
    cursor: "pointer"
  },
  grSubTitle: {
    color: "rgba(144, 202, 249, 1)",
    "&.selected": {
      opacity: 0.7
    },
    cursor: "pointer"
  },
  editTitle: {
    flex: "75%",
    display: "flex"
  },
  rippleEffect: {
    position: "relative",
    overflow: "hidden",
    "&::after": {
      content: "''",
      position: "absolute",
      width: "20px",
      height: "20px",
      background: "rgba(0, 0, 0, 0.2)",
      borderRadius: "50%",
      left: "10px",
      top: "50%",
      opacity: 0,
      transform: "translate(0, -50%) scale(0)",
      pointerEvents: "none"
    },
    "&.rippleActive::after": {
      animation:
        "$rippleAppear 0.1s linear forwards, $rippleSpread 0.3s linear 0.2s forwards"
    }
  },
  "@keyframes rippleAppear": {
    "0%": {
      transform: "translate(0, -50%) scale(0)",
      opacity: 0
    },
    "100%": {
      transform: "translate(0, -50%) scale(1)",
      opacity: 0.8
    }
  },
  "@keyframes rippleSpread": {
    "0%": {
      transform: "translate(0, -50%) scale(1)",
      opacity: 0.8
    },
    "100%": {
      transform: "translate(0, -50%) scale(40)",
      opacity: 0
    }
  }
}));

export default function QuestionHeader({
  isSelected,
  qId,
  internalQId,
  isGr,
  isUserGrQuestion,
  onClick,
  onExpand,
  title,
  subTitle,
  onCardDrop = () => {},
  disableDrop = false,
  forceQuestionSelection = false,
  onDelete = () => {},
  editable = true,
  updateQuestion = () => {},
  index,
  showRippleEffect,
  expandForFirstHighlight
}) {
  const classes = useStyles();
  const [isHovered, setHovered] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const controls = useAnimation();
  const isTask = !!useMatch({ path: "/task" });
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const textDirection = useSelector((state) => selectTextDirection(state));
  const [mousePos, setMousePos] = useState({});
  const selectedQuestionId = useSelector(
    (state) => state.interactions.selectedInteractionId
  );
  const [editingMode, setEditingMode] = useState(null);

  // Ephemeral State
  const [{ isOver }, drop] = useDrop({
    accept: "QUESTION_CARD",
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        targetId: monitor.targetId
      };
    },
    drop: (item) => {
      setExpanded(true);
      onCardDrop(item, qId);
    },
    canDrop: () => !disableDrop
  });

  //Behavior
  useEffect(() => {
    if (isHovered || isOver) {
      controls.start("hovered");
    } else {
      controls.start(isSelected ? "selected" : "default");
    }
  }, [isHovered, isSelected, isOver]);

  useEffect(() => {
    onExpand(expanded);
  }, [expanded]);

  useEffect(() => {
    if (expandForFirstHighlight) {
      setExpanded(true);
    }
  }, [expandForFirstHighlight]);

  useEffect(() => {
    if (editingMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editingMode, inputRef]);

  function deleteQuestion() {
    setMousePos({});
    onDelete(qId);
  }

  function handleIconKeyDown(e) {
    if (e.key === "Enter" || e.key === " ") {
      e.stopPropagation();
      e.preventDefault();
      setExpanded(!expanded);
    }
  }

  const onQuestionClick = () => {
    onClick(forceQuestionSelection ? qId : isSelected ? false : qId);
  };

  return (
    <motion.div
      ref={drop}
      className={clsx(
        classes.header,
        editingMode && classes.selectedHeader,
        classes.rippleEffect,
        showRippleEffect && "rippleActive"
      )}
      initial={false}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onQuestionClick}
      transition={{ duration: 0.1 }}
      data-test-class="question-header"
      variants={{
        selected: {
          background: isTask
            ? "rgba(144, 202, 249, 1)"
            : "rgba(94, 200, 145, 1)",
          color: "rgba(0, 0, 0, 0.87)"
        },
        default: {
          background: isTask
            ? "rgba(144, 202, 249, 0)"
            : "rgba(94, 200, 145, 0)",
          color: "#FFF"
        },
        hovered: {
          background: isSelected
            ? isTask
              ? "rgba(144, 202, 249, 0.7)"
              : "rgba(94, 200, 145, 0.7)"
            : isTask
              ? "rgba(144, 202, 249, 0.1)"
              : "rgba(94, 200, 145, 0.1)",
          color: isSelected ? "rgba(0, 0, 0, 0.87)" : "#FFF"
        }
      }}
      animate={controls}>
      <IconButton
        aria-expanded={expanded}
        aria-label={
          expanded
            ? `Collapse question no. ${index + 1} ("${title}") highlight`
            : `Expand question no. ${index + 1} ("${title}") highlights`
        }
        onClick={(e) => {
          e.stopPropagation();
          setExpanded(!expanded);
        }}
        onKeyDown={handleIconKeyDown}
        className={clsx({
          [classes.selected]: isSelected
        })}
        style={{
          transform: expanded
            ? textDirection === "rtl"
              ? "rotate(-90deg)"
              : "rotate(90deg)"
            : "rotate(0)",
          color: isSelected ? "rgba(0, 0, 0, 0.87)" : null
        }}>
        {textDirection === "rtl" ? (
          <TriangleLeft size={24} />
        ) : (
          <TriangleRight size={24} />
        )}
      </IconButton>
      {editable && editingMode === qId ? (
        <div className={classes.editTitle} data-no-dnd="true">
          <EditingButton
            multiline={true}
            text={title}
            editingMode={editingMode === qId}
            editIcon={<span />}
            onFocusOut={(e) => {
              updateQuestion(e);
              setEditingMode(null);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setEditingMode(null);
                updateQuestion(e.target.value);
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              e.preventDefault();
            }}
          />
        </div>
      ) : (
        <div className={classes.title}>
          <Typography
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                onClick(
                  forceQuestionSelection ? qId : isSelected ? false : qId
                );
              }
            }}
            tabIndex={0}
            role="button"
            aria-pressed={isSelected}>
            {title}
          </Typography>
          {!isUserGrQuestion && subTitle && (
            <Typography
              variant="body2"
              className={clsx(
                isGr && !isSelected && classes.grSubTitle,
                isSelected && classes.subTitle,
                !isSelected && classes.grSubTitle
              )}>
              {subTitle}
            </Typography>
          )}
        </div>
      )}
      {editingMode !== qId && (
        <QuestionEditMenu
          editable={editable}
          questionId={qId}
          className={classes.menu}
          selected={selectedQuestionId === qId}
          open={"X" in mousePos}
          handleClose={() => {
            setMousePos({});
          }}
          onDelete={deleteQuestion}
          onEdit={() => {
            grAPI.updateGrState({ grQuestionId: qId });
            grAPI.updateSelectedQuestionId(qId);
            setEditingMode(qId);
            setMousePos({});
          }}
          onColor={(color) => {
            dispatch(setInteractionColor(qId, color));
            interactionsAPI.updateHighlightsColorByQuestionId(
              qId,
              internalQId,
              color
            );
          }}
          mouseX={mousePos ? mousePos.X : null}
          mouseY={mousePos ? mousePos.Y : null}
        />
      )}
    </motion.div>
  );
}

QuestionHeader.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.node,
  qId: PropTypes.string.isRequired,
  isGr: PropTypes.bool.isRequired,
  isUserGrQuestion: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  onCardDrop: PropTypes.func.isRequired,
  disableDrop: PropTypes.bool,
  forceQuestionSelection: PropTypes.bool,
  onDelete: PropTypes.func,
  onSortQuestions: PropTypes.func,
  editable: PropTypes.bool,
  updateQuestion: PropTypes.func,
  index: PropTypes.number,
  showRippleEffect: PropTypes.bool,
  expandForFirstHighlight: PropTypes.bool
};

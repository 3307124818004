import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { COMMENT_PANEL_VIEW, FEATURES } from "../../consts";
import { useQuery } from "../../hooks";
import commentsAPI from "../../api/comments";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { selectSubmission } from "../../redux/tasksSlice";
import { isPdfSelector, selectCurrentText } from "../../redux/textsSlice";
import {
  setCommentPanelState,
  initializeCommentsMode
} from "../../redux/realtimeInteractionsSlice";
import {
  selectCommentsPrivacy,
  selectAvatar,
  selectTextDirection
} from "../../redux/firestoreSelectors";
import { useFirestoreConnect } from "../../hooks/useFirestoreConnect";

//Components
import NewTextEditor from "../SharedComponents/textEditor/NewTextEditor";

// Material UI
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { selectSelectedHighlight } from "../../redux/highlightSlice";
import PrivacyDropdown from "./PrivacyDropdown";
import { CustomAvatar } from "../SharedComponents";
import NavigateBackButton from "./NavigateBackButton";
import {
  setAnnotatorMode,
  setDetachedCommentMode
} from "../../redux/readerActionsSlice";
import { setSelectedQuestion } from "../../redux/grSlice";
import { setSelectedInteractionId } from "../../redux/interactionsSlice";

const useStyles = makeStyles((theme) => ({
  commentContainer: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 120px)",
    width: "100%",
    maxWidth: "300px"
  },
  commentHeader: {
    display: "inline-flex",
    color: theme.palette.text.blue,
    fontWeight: 600,
    letterSpacing: "1px",
    textDecoration: "none",
    borderBottom: `1px solid ${theme.palette.divider}`,
    alignContent: "flex-start"
  },
  commentHeaderMenu: {
    fontSize: "13px",
    maringTop: theme.spacing(2),
    float: "left",
    clear: "none"
  },
  userInput: {
    zIndex: 2,
    maxHeight: "calc(100vh - 280px)",
    overflow: "auto",
    paddingInline: theme.spacing(2),
    marginBlockEnd: theme.spacing(2),
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    fontSize: 14
  },
  outlined: {
    border: `1px solid ${theme.palette.divider}`
  },
  button: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    color: theme.palette.lightText.muted,
    paddingInline: theme.spacing(1),
    paddingBlock: theme.spacing(0.5)
  },
  ActiveBtn: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrast,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.text.main
    }
  },

  avatar: {
    background: "#0288D1",
    height: "26px",
    width: "26px",
    fontSize: "small"
  },
  userName: {
    marginLeft: theme.spacing(1)
  },
  nameAndAvatar: {
    display: "inline-flex",
    paddingInline: theme.spacing(2),
    paddingBlockEnd: theme.spacing(1.8)
  }
}));

function NewThread() {
  // Hooks
  let { text_id, submission_id } = useQuery();
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const editorRef = useRef(null);

  // Ephemeral state
  const [cancelButton, setCancelButton] = useState(true);
  const [comment, setComment] = useState({});

  // Redux State
  const text = useSelector(selectCurrentText);
  const privacy = useSelector((state) => selectCommentsPrivacy(state));

  const textDirection = useSelector((state) => selectTextDirection(state));
  const currentUser = useSelector((state) => state.firebase.auth);
  const isPdf = useSelector(isPdfSelector);
  const avatar = useSelector((state) => selectAvatar(state));
  const selectedCourseId = useSelector(
    (state) => state.user.userProfile.selectedCourseId
  );

  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const action = useSelector(
    (state) => state.readerActions.persistentActionState.actionBar
  );
  const selectedHighlight = useSelector((state) =>
    selectSelectedHighlight(state)
  );
  const annotatorMode = useSelector(
    (state) => state.readerActions.persistentActionState.annotatorMode
  );
  const newCommentObject = useSelector(
    (state) => state.realtimeInteractions.newComment
  );
  const activeQuestion = useSelector(
    (state) => state.interactions.selectedInteractionId
  );
  const firstQuestionObject = useSelector(
    (state) => state.interactions.questions[0]
  );
  const userId = useSelector((state) => state.firebase.auth.uid);
  const detachedComment = useSelector(
    (state) => state.readerActions.detachedComment
  );
  useFirestoreConnect([
    {
      collection: "userProfiles",
      doc: "customConfig",
      subcollections: [{ collection: userId, doc: FEATURES.COMMENTS }],
      storeAs: "commentsCustomConfig"
      // type: "once"
    }
  ]);
  //Deravied state

  //Variables

  // Behavior
  useEffect(() => {
    dispatch(initializeCommentsMode());
  }, []);

  async function createCommentThread(content) {
    let course_id;
    let task_id;
    const { richText, plainText, wordCount } = content;
    let { cfi, pdfPosition } = detachedComment
      ? newCommentObject
      : selectedHighlight;

    if (!text_id) {
      course_id = selectedCourseId;
      text_id = text.id;
      task_id = submission.task_id;
    } else {
      course_id = text.course_id;
      task_id = null;
    }

    const interaction = {
      user_name: currentUser.displayName,
      content: plainText,
      cfi: cfi,
      rich_text: richText,
      word_count: wordCount,
      course_id: course_id,
      text_id,
      privacy: privacy,
      isPdf: isPdf,
      pdfPosition: pdfPosition,
      task_id: task_id,
      text_name: text.name
    };
    commentsAPI.createThread(interaction).then((success) => {
      if (success) {
        dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.ALL_THREADS)); // display comments panel with all threads
        // TODO: open snackbar
        // Implament Undo
        dispatch(setDetachedCommentMode(false));
        // reset readerActions state based on view
        if (["poc", ""].includes(action)) dispatch(setAnnotatorMode(action));
        else dispatch(setAnnotatorMode("highlight"));
        if (activeQuestion) {
          dispatch(setSelectedInteractionId(activeQuestion));
          dispatch(setSelectedQuestion(activeQuestion));
        } else {
          dispatch(setSelectedInteractionId(firstQuestionObject?.id));
          dispatch(setSelectedQuestion(firstQuestionObject?.id));
        }
      }
    });
  }

  // Add effect to focus on mount
  useEffect(() => {
    // Focus the editor when component mounts
    if (editorRef.current) {
      editorRef.current.focus();
    }
  }, []);
  return (
    <Box
      className={clsx(classes.commentContainer, classes.outlined)}
      dir={textDirection}>
      <Box className={clsx(classes.commentHeader)}>
        <NavigateBackButton />
        <PrivacyDropdown />
      </Box>
      <Box style={{ marginTop: "15px" }}>
        <Box className={classes.nameAndAvatar}>
          <CustomAvatar
            className={classes.avatar}
            id={currentUser.uid}
            name={currentUser.displayName}
            src={avatar}
            minimized={true}
          />
          <Typography
            variant="body2"
            className={clsx(classes.userName, "sentry-mask")}>
            {currentUser.displayName || "me"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            "& .editorContainer ": { padding: "16px", borderWidth: "0" },
            "& .editor ": { minHeight: "50px" }
          }}>
          <NewTextEditor
            ref={editorRef}
            onContentChange={(content) => {
              setComment(content);
              if (content.plainText.length > 0) {
                setCancelButton(false);
              } else {
                setCancelButton(true);
              }
            }}
            onEnterPress={() => {
              createCommentThread(comment);
              setComment({});
            }}
            placeholder={intl.formatMessage({
              id: "comments.thread.newComment",
              defaultMessage: "My comment"
            })}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              paddingLeft: "16px"
            }}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              disableElevation
              className={classes.sendButton}
              disabled={cancelButton}
              onClick={() => {
                createCommentThread(comment);
                setComment({});
              }}>
              {intl.formatMessage({
                id: "comments.thread.postReply",
                defaultMessage: "Comment"
              })}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default NewThread;

import React, { useReducer } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Card } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ThemeProvider } from "@mui/material/styles";
import { useGetTheme } from "../../../hooks";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    padding: theme.spacing(2),
    transition: "background 0.35s"
  }
}));

export const initialState = {
  collapseContent: false,
  collapseAfter: 4,
  numberOfLines: 1,
  collapseStatus: "DISABLED"
};

export function reducer(state, action) {
  switch (action.type) {
    case "setNumberOfLines": {
      let { collapseAfter, collapseContent, collapseStatus } = state;
      const numberOfLines = action.payload;
      // we are getting the number of lines from the content element

      if (collapseContent && numberOfLines > collapseAfter) {
        collapseStatus = "COLLAPSED";
      }
      if (numberOfLines < collapseAfter) {
        collapseStatus = "DISABLED";
      }

      return { ...state, numberOfLines: action.payload, collapseStatus };
    }
    case "toggleCollapseStatus": {
      const { collapseStatus } = state;
      const newCollapseStatus =
        collapseStatus === "EXPANDED" ? "COLLAPSED" : "EXPANDED";
      return { ...state, collapseStatus: newCollapseStatus };
    }
    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
}
const InteractionCard = React.forwardRef((props, ref) => {
  const {
    className,
    children,
    interaction,
    color = "secondary",
    collapseAfter = 4,
    collapseContent = false
  } = props;

  const classes = useStyles();
  const theme = useGetTheme();

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    collapseAfter,
    collapseContent
  });

  // Behavior

  return (
    // The theme provider is needed because of legacy code that was setting an override theme for the sidebar. this should be refactored and removed
    <ThemeProvider theme={theme}>
      <Card
        data-testid="card-component"
        ref={ref}
        elevation={1}
        className={clsx(classes.card, className)}
        role="article"
        tabIndex={0}>
        <div role="region" aria-label="Highlight">
          {React.Children.map(children, (child) => {
            return React.cloneElement(child, {
              interaction,
              color,
              dispatch,
              collapseContent: state.collapseContent,
              collapseAfter: state.collapseAfter,
              numberOfLines: state.numberOfLines,
              collapseStatus: state.collapseStatus
            });
          })}
        </div>
      </Card>
    </ThemeProvider>
  );
});

InteractionCard.propTypes = {
  className: PropTypes.string,
  disable: PropTypes.bool,
  color: PropTypes.string,
  collapseAfter: PropTypes.number,
  interaction: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  collapseContent: PropTypes.bool
};
InteractionCard.displayName = "InteractionCard";

export default InteractionCard;

import React, { useState, useRef } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import TooltipWithIntl from "../SharedComponents/tooltip/TooltipWithIntl";

const useStyles = makeStyles((theme) => ({
  annotateBar: {},
  btn: {
    display: "inline",
    WebkitTapHighlightColor: "transparent" // Remove default touch highlight
  },
  disabledBtn: {
    cursor: "not-allowed",
    marginInlineEnd: theme.spacing(2),
    color: "#aaaaaa"
  },
  highlightBtn: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginInline: theme.spacing(1),
    "&:hover": {
      color: "#BEF3BF"
    },
    "&:focus": {
      outline: "2px solid #BEF3BF"
    },
    // Increase touch target size while keeping visual size
    "@media (pointer: coarse)": {
      padding: theme.spacing(1),
      margin: theme.spacing(0.5),
      minHeight: 44,
      minWidth: 44,
      justifyContent: "center"
    }
  },
  pressed: {
    transform: "scale(0.95)",
    transition: "transform 0.1s"
  },
  tooltip: {
    height: "24px"
  }
}));

const ReaderAction = React.forwardRef(
  (
    {
      Icon,
      selected,
      style,
      onMouseEnter,
      onMouseLeave,
      enabled = true,
      minimal = false,
      intlStringId,
      defaultMessage,
      placement,
      handleClick
    },
    ref
  ) => {
    const classes = useStyles();
    const [isPressed, setIsPressed] = useState(false);
    const touchStartRef = useRef(null);

    const handleKeyDown = (event) => {
      if (event.key === "Enter" || event.key === " ") {
        handleClick();
      }
    };

    const handleTouchStart = (event) => {
      if (!enabled) return;

      setIsPressed(true);

      const touch = event.touches[0];
      touchStartRef.current = {
        x: touch.clientX,
        y: touch.clientY,
        time: Date.now()
      };
    };

    const handleTouchMove = (event) => {
      if (!touchStartRef.current || !enabled) return;

      const touch = event.touches[0];
      const deltaX = Math.abs(touch.clientX - touchStartRef.current.x);
      const deltaY = Math.abs(touch.clientY - touchStartRef.current.y);

      // Cancel press if finger moves more than 10px
      if (deltaX > 10 || deltaY > 10) {
        setIsPressed(false);
        touchStartRef.current = null;
      }
    };

    const handleTouchEnd = (event) => {
      if (!enabled) return;

      setIsPressed(false);

      if (touchStartRef.current) {
        const touchDuration = Date.now() - touchStartRef.current.time;

        // Only trigger if the touch was short (to differentiate from scrolling)
        if (touchDuration < 500) {
          handleClick();
        }
      }

      touchStartRef.current = null;
    };

    return (
      <Box
        ref={ref}
        role="button"
        tabIndex={enabled ? 0 : -1}
        aria-disabled={!enabled}
        className={clsx(
          classes.btn,
          enabled && classes.highlightBtn,
          !enabled && classes.disabledBtn,
          minimal && classes.minimal,
          isPressed && enabled && classes.pressed
        )}
        onClick={enabled ? handleClick : undefined}
        onKeyDown={enabled ? handleKeyDown : undefined}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={() => {
          setIsPressed(false);
          touchStartRef.current = null;
        }}
        style={{
          backgroundColor: selected ? "rgba(255,255,255, 0.15)" : "transparent",
          borderRadius: 3,
          transform: isPressed && enabled ? "scale(0.95)" : "scale(1)",
          transition: "transform 0.1s",
          ...style
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}>
        <TooltipWithIntl
          intlStringId={intlStringId}
          defaultMessage={defaultMessage}
          placement={placement}
          className={classes.tooltip}>
          <Icon
            fontSize={"medium"}
            style={{
              pointerEvents: "none" // Prevent icon from interfering with touch
            }}
          />
        </TooltipWithIntl>
      </Box>
    );
  }
);

ReaderAction.displayName = "ReaderAction";
export default ReaderAction;

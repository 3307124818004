import React, { useEffect, useRef } from "react";
import EpubCifi from "epubjs/lib/epubcfi";
import { getScrolledRectPosition } from "../../annotations/utils";

const NativeTouchHighlightHandler = ({
  rendition,
  container,
  onHighlightCreated
}) => {
  const selectionTimeoutRef = useRef(null);
  const touchStartTimeRef = useRef(null);
  const lastTouchEndRef = useRef(0);

  useEffect(() => {
    if (!rendition) return;
    const contents = rendition.getContents()[0];
    if (!contents?.document) return;

    // Track if we're handling a touch-initiated selection
    let isTouchSelection = false;

    const handleTouchStart = (e) => {
      touchStartTimeRef.current = Date.now();
    };

    const handleTouchEnd = (e) => {
      // Check if this was a quick tap (to avoid interfering with normal taps)
      const touchDuration = Date.now() - touchStartTimeRef.current;
      lastTouchEndRef.current = Date.now();

      if (touchDuration < 500) {
        // Quick tap - don't interfere
        return;
      }

      // Set flag for touch selection
      isTouchSelection = true;

      // Clear any existing timeout
      if (selectionTimeoutRef.current) {
        clearTimeout(selectionTimeoutRef.current);
      }

      // Wait a bit to allow native selection UI to settle
      selectionTimeoutRef.current = setTimeout(checkSelection, 200);
    };

    const checkSelection = () => {
      const selection = contents.window.getSelection();
      if (!selection || selection.isCollapsed || !selection.rangeCount) return;

      const range = selection.getRangeAt(0);
      const text = selection.toString().trim();

      if (!text) return;

      // Create CFI range and get positions
      const cfiRange = new EpubCifi(range, contents.cfiBase).toString();
      const containerRect = container.current.getBoundingClientRect();
      const iframeRect =
        contents.document.defaultView.frameElement.getBoundingClientRect();
      const elementRect = range.getBoundingClientRect();
      const scrolledRect = getScrolledRectPosition(
        containerRect,
        iframeRect,
        elementRect
      );

      // Call highlight callback
      onHighlightCreated({
        selection: { content: text, cfi: cfiRange },
        clientRect: scrolledRect
      });

      // Reset touch selection flag
      isTouchSelection = false;
    };

    const handleSelectionChange = () => {
      // Only handle selection changes that weren't initiated by touch
      if (!isTouchSelection) {
        // Don't process if this was right after a touch end
        const timeSinceLastTouch = Date.now() - lastTouchEndRef.current;
        if (timeSinceLastTouch < 250) return;

        if (selectionTimeoutRef.current) {
          clearTimeout(selectionTimeoutRef.current);
        }
        selectionTimeoutRef.current = setTimeout(checkSelection, 200);
      }
    };

    // Add event listeners
    contents.document.addEventListener("touchstart", handleTouchStart, {
      passive: true
    });
    contents.document.addEventListener("touchend", handleTouchEnd, {
      passive: true
    });
    contents.document.addEventListener(
      "selectionchange",
      handleSelectionChange
    );

    // Cleanup
    return () => {
      if (selectionTimeoutRef.current) {
        clearTimeout(selectionTimeoutRef.current);
      }
      contents.document.removeEventListener("touchstart", handleTouchStart);
      contents.document.removeEventListener("touchend", handleTouchEnd);
      contents.document.removeEventListener(
        "selectionchange",
        handleSelectionChange
      );
    };
  }, [rendition, container, onHighlightCreated]);

  return null;
};

export default NativeTouchHighlightHandler;

// Dependencies
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import ePub from "epubjs";
import { useIntl } from "react-intl";
import { useQuery, useGetTheme, useStepStage } from "../../hooks";
import { TASK } from "../../consts";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectQuestions,
  selectGrTaskQuestions,
  selectUserGrQuestions,
  addNewQuestion
} from "../../redux/interactionsSlice";
import { grAPI, interactionsAPI, standardTaskAPI } from "../../api";
import { selectTask, selectSubmission } from "../../redux/tasksSlice";
import { isPdfSelector } from "../../redux/textsSlice";
import { selectCourseByTextId } from "../../redux/coursesSlice";

// Components
import NewQuestion from "./NewQuestion";
import Question from "./Question";

import makeStyles from "@mui/styles/makeStyles";
import {
  Button,
  StyledEngineProvider,
  ThemeProvider,
  Box,
  Divider
} from "@mui/material";
import { ScrollBox } from "../SharedComponents";
import {
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import AdaptingHighlightTooltip from "./AdaptingHighlightTooltip";
import useSelectedQuestion from "../../hooks/firestore/useSelectedQuestion";

const useStyles = makeStyles((theme) => ({
  questions: {
    paddingInline: 0,
    "&.focused": { height: "unset" }
  },
  userQuestions: {
    overflow: "hidden"
  },
  question: {
    paddingBlock: 0,
    "&:not(:last-child)": {
      borderBottom: "1px solid rgba(255, 255, 255, 0.1)"
    }
  },
  forceBottomBorder: {
    "&:last-child": {
      borderBottom: "1px solid rgba(255, 255, 255, 0.1)"
    }
  },
  panelHeader: {
    color: "white",
    background: theme.palette.background.sidePanel,
    display: "flex",
    justifyContent: "space-between",
    paddingBlock: theme.spacing(2),
    paddingInline: theme.spacing(1.5),
    zIndex: 10,
    width: "100%"
  },
  left: {
    textAlign: "left"
  },
  questionIndexContainer: {
    display: "flex",
    alignItems: "center"
  },
  divider: {
    backgroundColor: "#484848"
  }
}));

export default function Questions({
  scrollToBottom,
  locked = false,
  rendition
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const EpubCFI = new ePub.CFI();
  const theme = useGetTheme({ alwase: "dark" });
  const intl = useIntl();
  const { submission_id } = useQuery();
  //Redux State
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const allQuestions = useSelector((state) => state.interactions.questions);
  const taskQuestions = useSelector((state) =>
    selectQuestions(state, submission.task_id)
  );
  const grTaskQuestions = useSelector(selectGrTaskQuestions);
  const userGrQuestions = useSelector(selectUserGrQuestions);
  const highlights = useSelector((state) => state.interactions.highlights);
  const answers = useSelector((state) => state.interactions.answers);
  const selectedTextId = useSelector((state) => state.texts.selectedTextId);

  const task = useSelector((state) => selectTask(state, submission.task_id));
  const [step] = useStepStage();
  const selectedCourseId = useSelector(selectCourseByTextId)?.id;
  const isPdf = useSelector(isPdfSelector);
  const newQuestion = useSelector((state) => state.interactions.newQuestion);

  // Ephemeral State
  const [newQuestionMode, setNewQuestionMode] = useState({
    adding: false,
    value: ""
  });
  const [instructorQuestions, setInstructorQuestions] = useState(
    taskQuestions || grTaskQuestions
  );
  const [expandedItems, setExpandedItems] = useState([]);

  //Derived state
  const isTask = !!submission_id;
  const color = isTask ? "primary" : "secondary";
  const pageType = task.task_type === TASK.TYPE.STANDARD ? "task" : "gr";

  const selectedQuestionId = useSelectedQuestion(
    isTask ? task.id : null,
    selectedTextId
  );

  //Behavior
  useEffect(() => {
    if (allQuestions.length) {
      let question = selectedQuestionId
        ? allQuestions.find((q) => q.id === selectedQuestionId)
        : instructorQuestions[0] || userGrQuestions[0] || null;
      if (selectedQuestionId === "") {
        // Still loading state, do nothing, when state is loaded, selectedQuestionId will be either "" or an id,
        // the effect would be called again and selectQuetion would be called.
        return;
      }
      // Either question was found in state or it's the first question, select it anyway.
      question && selectQuestion(question);
    }
  }, [selectedQuestionId, step, instructorQuestions.length]);

  const selectQuestion = (question) => {
    if (question.task_id) {
      standardTaskAPI.updateSelectedQuestionId(question.id, submission.task_id);
    } else {
      grAPI.updateSelectedQuestionId(question.id);
    }
  };

  useEffect(() => {
    if (newQuestionMode.addValue && newQuestionMode.shouldAdd) {
      setNewQuestionMode({
        value: "",
        adding: newQuestionMode.adding,
        addValue: "",
        shouldAdd: false
      });
    }
  }, [newQuestionMode, dispatch, highlights, allQuestions, selectedTextId]);

  useEffect(() => {
    setInstructorQuestions(
      pageType === "task" ? taskQuestions : grTaskQuestions
    );
  }, [taskQuestions, grTaskQuestions]);

  useEffect(() => {
    if (newQuestion) {
      addQuestion();
      dispatch(addNewQuestion(false));
    }
  }, [newQuestion]);

  const onDeleteQuestion = async (question, highlights) => {
    interactionsAPI.deleteQuestion(question, highlights);
    allQuestions.length > 0 && selectQuestion(allQuestions[0]);
  };

  function addQuestion() {
    scrollToBottom();
    grAPI.updateSelectedQuestionId(null);
    if (!newQuestionMode.adding) {
      setNewQuestionMode({ adding: true, value: "" });
    }
  }

  const handleDroppingHighlight = (item, questionId) => {
    const questionHighlights = highlights.filter(
      (h) => h.interaction_id === questionId
    );
    if (questionHighlights?.find((h) => h.cfi === item.highlight.cfi)) return;
    const order = Number(questionHighlights.length);
    const highlight = {
      ...item.highlight,
      ...{
        interaction_id: questionId,
        order: order
      }
    };
    const { id, ...newHighlight } = highlight;
    interactionsAPI.createHighlight(
      newHighlight,
      selectedTextId,
      selectedCourseId
    );
  };

  const handleSortQuestions = (activeQuestionId, overQuestionIdOrDirection) => {
    const currentOrder = userGrQuestions.map((q) => q.id);
    const activeIndex = currentOrder.indexOf(activeQuestionId);

    // Handle keyboard-based movement
    if (typeof overQuestionIdOrDirection === "number") {
      const newIndex = activeIndex + overQuestionIdOrDirection;
      if (newIndex < 0 || newIndex >= currentOrder.length) {
        return; // Prevent out-of-bounds movement
      }
      const order = [...currentOrder];
      const [movedQuestion] = order.splice(activeIndex, 1);
      order.splice(newIndex, 0, movedQuestion);
      interactionsAPI.updateQuestionOrder(order, currentOrder);
      return;
    }

    // Handle mouse-based drag-and-drop
    if (typeof overQuestionIdOrDirection === "string") {
      const overIndex = currentOrder.indexOf(overQuestionIdOrDirection);
      if (overIndex === -1) {
        return; // Ensure overQuestionId is valid
      }
      const order = [...currentOrder];
      order.splice(activeIndex, 1);
      order.splice(overIndex, 0, activeQuestionId);
      interactionsAPI.updateQuestionOrder(order, currentOrder);
    }
  };

  const renderAddQuestionButton = () => {
    if (pageType === "gr" && step === "highlight") {
      return (
        <Box className={clsx(classes.panelHeader, classes.left)}>
          <Button color={color} variant="contained" onClick={addQuestion}>
            {intl.formatMessage({
              id: "gr.addQuestion",
              defaultMessage: "Add Question"
            })}
          </Button>
        </Box>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div id="questions">
      <AdaptingHighlightTooltip />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {renderAddQuestionButton()}
          <>
            <ScrollBox className={clsx(classes.questions)}>
              {instructorQuestions.map((question, index) => (
                <Question
                  key={question.id}
                  disableActions={
                    submission.status === TASK.SUBMISSION_STATUS.SUBMITTED
                  }
                  selectedQuestionId={selectedQuestionId}
                  question={question}
                  showInText={step === "highlight" ? "inline" : "dialog"}
                  onCardDrop={handleDroppingHighlight}
                  rendition={rendition}
                  newQuestionMode={newQuestionMode.adding}
                  forceQuestionSelection={step !== "highlight"}
                  expandedItems={expandedItems}
                  setExpandedItems={setExpandedItems}
                  index={index}
                />
              ))}

              {pageType === "gr" && (
                <SortableContext
                  items={userGrQuestions.map(
                    (q) => `sort-student-questions-${q.id}`
                  )}
                  strategy={verticalListSortingStrategy}>
                  <Box className={clsx(classes.userQuestions)}>
                    {userGrQuestions.length !== 0 &&
                      instructorQuestions.length !== 0 && (
                        <Divider className={classes.divider} />
                      )}
                    {userGrQuestions.map((question, index) => (
                      <Question
                        selectedQuestionId={selectedQuestionId}
                        key={question.id}
                        disableActions={
                          submission.status === TASK.SUBMISSION_STATUS.SUBMITTED
                        }
                        question={question}
                        showInText={step === "highlight" ? "inline" : "dialog"}
                        onCardDrop={handleDroppingHighlight}
                        rendition={rendition}
                        index={index}
                        onSortQuestions={handleSortQuestions}
                        onDelete={onDeleteQuestion}
                        newQuestionMode={newQuestionMode.adding}
                        forceQuestionSelection={step !== "highlight"}
                        expandedItems={expandedItems}
                        setExpandedItems={setExpandedItems}
                      />
                    ))}
                  </Box>
                </SortableContext>
              )}

              {newQuestionMode.adding && (
                <NewQuestion
                  taskId={task.id}
                  submissionId={submission_id}
                  newQuestionMode={newQuestionMode}
                  setNewQuestionMode={setNewQuestionMode}
                  scrollToBottom={scrollToBottom}
                />
              )}
            </ScrollBox>
          </>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

Questions.propTypes = {
  scrollToBottom: PropTypes.func,
  locked: PropTypes.bool,
  rendition: PropTypes.object
};
